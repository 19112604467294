import {autoinject} from 'aurelia-framework';
import {AdministrationServer} from '../server/AdministrationServer';
import {Router} from 'aurelia-router';
import {GlobalState} from '../models/GlobalState';
import {ErrorObject} from '../models/ErrorObject';
import {backPage,clearFullDisplay} from '../resources/navigation/NextPage';
import {Routes} from '../routes';

@autoinject
@backPage()
@clearFullDisplay(true)
export class AdministrationRegisterUser extends Routes {

    public errorObject: ErrorObject = null;
    public isBusy: boolean = false;
    public userEmail: string = '';
    public name: string = '';
    public phoneNumber: string = '';

    constructor(protected router: Router, protected globalState: GlobalState, private server: AdministrationServer) {
        super();
        this.errorObject = ErrorObject.EmptyError();
    }

    public registerUser(): any {
        if (!this.isBusy) {
            this.errorObject = ErrorObject.EmptyError();
            this.isBusy = true;
            return this.server.registerUser(
                    this.userEmail,
                    this.name,
                    this.phoneNumber
                    ).then(response => {
                        this.isBusy = false;
                        this.router.navigate(Routes.CLIENT_HOME);
                    })
                    .catch(reason => 
                    {
                        this.errorObject = new ErrorObject(
                            reason,
                            this,
                            'Register user', 
                            'There has been an error reported attempting to register the new user detais on the system. Try again?');       
                        this.isBusy = false;
                    });
        }
    }
}
