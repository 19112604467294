import {Router, RouteConfig} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {computedFrom} from 'aurelia-framework';
import {SecurityRoles} from '../models/SecurityRoles';
import {GlobalState, IGlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer'
import {ErrorObject} from '../models/ErrorObject';
import {CardSortItem, ICardSortItems, selectType} from '../models/CardSortItem';
import {RoutePaths} from '../routes';

export class CardSortCore extends RoutePaths implements ICardSortItems, IGlobalState {
    public notList: CardSortItem[] = [];
    public slightList: CardSortItem[] = [];
    public maybeList: CardSortItem[] = [];
    public veryList: CardSortItem[] = [];
    public definiteList: CardSortItem[] = [];
    public cardList: CardSortItem[] = [];
    public currentCard: CardSortItem;
    public errorObject: ErrorObject = null;
    public isNextPageDisabled: boolean = true;
    public nextPageBtn: HTMLButtonElement;
    public isNextPageVisible: boolean = false;
    public isCurrentCardDisplayed: boolean = false;
    public isEndOfCards: boolean = false;
    public isReSortRequired: boolean = false;
    public isReSortStage: boolean = false;
    public reSortPhase: number = 0;
    public isBusy: boolean;
    protected isIndependentAssessment: boolean = false;
    protected setIdFragment: string = "0";

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, public authService: AuthService, public securityRoles: SecurityRoles) {
        super();
        this.isNextPageVisible = this.securityRoles.isQuestionnaireUser;
    }

    public activate(params: any, route: RouteConfig): any {
        if (params.id && params.id.length >= 36)
        {
            if (!this.globalState.questionnaire.id || !this.globalState.questionnaire.id != params.id)
            {
                this.globalState.questionnaire.id = params.id;
                this.globalState.questionnaire.loaded = false;
            }
        } else if (this.globalState.Id)  
        {
            // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
            this.router.navigateToRoute( route.name, { 'id': this.globalState.Id }, { trigger: false, replace: true });
        }
        
        if (this.globalState.questionnaire.id) {
            return this.server.loadCardData(this.globalState.questionnaire.id, this.setIdFragment, this.globalState, this).then(
                response => {
                    this.reRank(this.definiteList);
                    this.reRank(this.veryList);
                    this.reRank(this.maybeList);
                    this.reRank(this.slightList);
                    this.reRank(this.notList);

                    if (this.cardList.length > 0) {
                        this.currentCard = this.cardList[0];
                        this.isNextPageDisabled = true;
                        this.isCurrentCardDisplayed = true;
                        this.isEndOfCards = false;
                        this.isReSortStage = false;
                    }
                    else {
                        this.currentCard = null;
                        this.isCurrentCardDisplayed = false;
                        this.isNextPageDisabled = !this.securityRoles.isQuestionnaireUser && !this.isIndependentAssessment;
                    }
                });
        }
    }

    public deactivate(): any {
        this.globalState.clearFooterDetails();
    }

    @computedFrom('authService.authenticated')
    public get authenticated() {
      return this.authService.authenticated;
    }

    public clickHandler(event: any): boolean {
        // Check for the right element being clicked ...
        let model: CardSortItem = <CardSortItem>event.target.model;
        let name: string = event.target.name;
        if (!model && event.target.parentElement.model) {
            model = <CardSortItem>event.target.parentElement.model;
            name = event.target.parentElement.name;
        }

        if (!name) {
            return true;
        } else if (name === "selectDefinite") {
            this.addToCardPile(this.currentCard, selectType.isDefinite);
        } else if (name === "selectVery") {
            this.addToCardPile(this.currentCard, selectType.isVery);
        } else if (name === "selectMaybe") {
            this.addToCardPile(this.currentCard, selectType.isMaybe);
        } else if (name === "selectSlight") {
            this.addToCardPile(this.currentCard, selectType.isSlight);
        } else if (name === "selectNot") {
            this.addToCardPile(this.currentCard, selectType.isNot);
        } else if (name === "definiteItem") {
            this.removeFromCardPile(model, selectType.isDefinite);
        } else if (name === "veryItem") {
            this.removeFromCardPile(model, selectType.isVery);
        } else if (name === "maybeItem") {
            this.removeFromCardPile(model, selectType.isMaybe);
        } else if (name === "slightItem") {
            this.removeFromCardPile(model, selectType.isSlight);
        } else if (name === "notItem") {
            this.removeFromCardPile(model, selectType.isNot);
        }

        if (this.cardList.length > 0) {
            this.currentCard = this.cardList[0];
            this.isNextPageDisabled = true;
            this.isCurrentCardDisplayed = true;
        }
        else {
            this.currentCard = null;
            this.isCurrentCardDisplayed = false;
            this.isNextPageDisabled = !this.securityRoles.isQuestionnaireUser && !this.isIndependentAssessment;
            if (this.nextPageBtn)
            {
                this.nextPageBtn.disabled = false;
                this.nextPageBtn.focus();
            }
        }

        // let the event keep on bubbling ...
        return true;
    }

    private addToCardPile(card: CardSortItem, selectedPile: selectType): void {
        this.cardList.removeItem(card);
        card.cardPile = selectedPile;
        this.updateCardAnswer(card);
        if (selectedPile === selectType.isDefinite) {
            this.definiteList.push(card);
            this.reRank(this.definiteList);
        }
        else if (selectedPile === selectType.isVery) {
            this.veryList.push(card);
            this.reRank(this.veryList);
        }
        else if (selectedPile === selectType.isMaybe) {
            this.maybeList.push(card);
            this.reRank(this.maybeList);
        }
        else if (selectedPile === selectType.isSlight) {
            this.slightList.push(card);
            this.reRank(this.slightList);
        }
        else if (selectedPile === selectType.isNot) {
            this.notList.push(card);
            this.reRank(this.notList);
        }

        let noMoreCards = this.cardList.length === 0;
        if (noMoreCards && this.definiteList.length < 2)
        {
            this.isReSortRequired = true;
            this.isReSortStage = false;
            noMoreCards = false;    
        }

        this.isEndOfCards = noMoreCards;
    }

    public initialiseResort(): void{
        // Take all the items in the very pile and mark them as unsorted
        while (this.veryList.length > 0)
        {
            let card = this.veryList.pop(); 
            card.cardPile = selectType.isNone;
            this.cardList.push(card);
        }

        this.currentCard = this.cardList[0];
        this.isReSortRequired = false;
        this.isNextPageDisabled = true;
        this.isCurrentCardDisplayed = true;
        this.isReSortStage = true;
        this.isEndOfCards = false;
    }

    private removeFromCardPile(card: CardSortItem, item: selectType): void {
        card.cardPile = selectType.isNone;
        this.updateCardAnswer(card);
        this.removeCardFromPile(card, item);
        this.isEndOfCards = false;
    }

    private removeCardFromPile(card: CardSortItem, item: selectType): void {
        if (item === selectType.isDefinite) {
            let list = this.definiteList.removeItem(card);
            this.reRank(list);
            this.definiteList = list;
            this.cardList.unshift(card);
            this.currentCard = card;
        }
        else if (item === selectType.isVery) {
            let list = this.veryList.removeItem(card);
            this.reRank(list);
            this.veryList = list;
            this.cardList.unshift(card);
            this.currentCard = card;
        }
        else if (item === selectType.isMaybe) {
            let list = this.maybeList.removeItem(card);
            this.reRank(list);
            this.maybeList = list;
            this.cardList.unshift(card);
            this.currentCard = card;
        }
        else if (item === selectType.isSlight) {
            let list = this.slightList.removeItem(card);
            this.reRank(list);
            this.slightList = list;
            this.cardList.unshift(card);
            this.currentCard = card;
        }
        else if (item === selectType.isNot) {
            let list = this.notList.removeItem(card);
            this.reRank(list);
            this.notList = list;
            this.cardList.unshift(card);
            this.currentCard = card;
        }
    }

    private reRank(list: CardSortItem[]): void {
        let length = list.length;
        for (let i = 0; i < length; i++) {
            list[i].rank = length - i;
        }
    }

    protected updateCardAnswer(card: CardSortItem): void {
        this.isBusy = true;
        this.errorObject = ErrorObject.EmptyError();
        this.server.saveCardAnswer(this.globalState.questionnaire.id, '2', this.setIdFragment, card)
            .then(response => {
                // if there was any error on the previous attempt - throw it away now.
                this.isBusy = false;
            })
            .catch(reason => 
            {
                this.errorObject = new ErrorObject(
                    reason,
                    card,
                    'Save Card State', 
                    `The card with the title of "<span class='errorHighlight'>${card.description}</span>" has not had it's current state saved.`);       

                this.removeCardFromPile(card, card.cardPile);
                card.cardPile = selectType.isNone;
                this.isNextPageDisabled = true;
                this.isCurrentCardDisplayed = true;
                this.isBusy = false;
            });
    }
}

