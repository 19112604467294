import {autoinject, computedFrom} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {AccountsServer} from '../server/AccountsServer';
import {QuestionServer} from '../server/QuestionServer';
import {GlobalState} from '../models/GlobalState';
import {ErrorObject} from '../models/ErrorObject';
import {RiasecQuestionnaire} from '../models/RiasecQuestionnaire';
import {backPage,clearFullDisplay} from '../resources/navigation/NextPage';

@autoinject
@backPage()
@clearFullDisplay(true)
export class AccountsUpdateDetail {

    public firstname: string = '';
    public surname: string = '';
    public emailAddress: string = '';
    public appointment: string = '';
    public questionnaire: RiasecQuestionnaire;
    public errorObject: ErrorObject = null;
    public isResendBusy: boolean = false;
    public isUpdateBusy: boolean = false;

    constructor(protected router: Router, protected globalState: GlobalState, private server: AccountsServer, private questionServer: QuestionServer) {

    }

    public activate(params: any) {
        if (params.id)
        {
            this.globalState.questionnaire.id = params.id;
            this.globalState.questionnaire.loaded = false;
            this.questionServer.loadQuestionnaire(params.id, this.globalState)
                .then( questionniare => { 
                    this.questionnaire = questionniare;
                    this.AssignLocals();
                });
        }
        else
        {
            this.questionnaire = this.globalState.questionnaire;
            this.AssignLocals();
        }
    }

    private AssignLocals(): void
    {
        if (this.questionnaire && this.questionnaire.id && this.questionnaire.firstname)
        {
            let obj = this.questionnaire;
            this.firstname = obj.firstname;
            this.surname = obj.surname;
            this.emailAddress = obj.emailAddress;
            this.appointment = (obj.appointment) ? obj.appointment.toISOString().substring(0,10) : "";
        }
    }

    public updateDetails(): any {
        if (!this.isUpdateBusy) {
            this.isUpdateBusy = true;
            this.errorObject = ErrorObject.EmptyError();
            this.server.updateQuestionnaireDetails(this.questionnaire.id, this.firstname, this.surname, this.emailAddress, (this.appointment) ? new Date(this.appointment): null)
                .then(response => {
                        this.isUpdateBusy = false;
                        this.router.navigateBack();
                    })
                .catch(reason => {
                    this.errorObject = new ErrorObject(
                        reason,
                        this,
                        'Update accessible questionniare pages', 
                        'Something happened when attempting to update the list of accessible pages. Try again? Probably not much you can do.');       
                    this.isUpdateBusy = false;
                });
        }
	}


    @computedFrom("questionnaire","questionnaire.id")
    public get isUpdateNotValid(): boolean {
        return (this.questionnaire == null) || (this.questionnaire.id == null);
    }
}
