
export class Routes
{
    public static readonly USER:string = 'administration/user';
    public static readonly USERS:string = 'administration/users';
    public static readonly USER_BILLING:string = 'administration/billing';
    public static readonly USER_REGISTER:string = 'administration/registerUser';
    public static readonly USER_ASSESSMENTS:string = 'administration/assessments';
    public static readonly QUESTIONNAIRE_COPY:string = 'administration/assessmentCopy';

    public static readonly CLIENT_HOME:string = "accounts/login";
    public static readonly CLIENT_LIST:string = "accounts/clients";
    public static readonly CLIENT_UPDATEDETAILS:string = 'accounts/updateDetail';
    public static readonly CLIENT_UPDATEPAGES:string = 'accounts/updateAssessment';
    public static readonly CLIENT_SENDASSESSMENT:string = 'accounts/sendAssessment';
    public static readonly CLIENT_ASSESSMENT:string = 'accounts/assessment';
    public static readonly USER_PROFILE:string = 'accounts/profile';
    
    public static readonly CARDS_VALUES:string = "assessment/cards/values";
    public static readonly CARDS_MEANINGS:string = 'assessment/cards/meanings';
    public static readonly CARDS_SKILLS:string = "assessment/cards/skills";
    public static readonly CARDS_INTERESTS:string = "assessment/cards/interests";
    public static readonly CARDS_ARTS:string = "assessment/cards/arts";
    public static readonly CARDS_BUSINESS:string = "assessment/cards/business";
    public static readonly CARDS_OCCUPATIONS:string = "assessment/cards/occupational";
    public static readonly CARDS_ENGINEERING:string = "assessment/cards/engineering";
    public static readonly CARDS_CREATIVITY:string = "assessment/cards/creativity";
    public static readonly CARDS_HELPING:string = "assessment/cards/helping";
    public static readonly CARDS_SCIENCE:string = "assessment/cards/science";
    public static readonly CARDS_TRADES:string = "assessment/cards/trades";
    public static readonly CARDS_STATEMENT:string = "assessment/cards/summary";

    public static readonly QUESTIONNAIRE:string = "assessment";
    public static readonly QUESTIONNAIRE_PAYMENT:string = "assessment/paymentresult";

    public static readonly Q40:string = "assessment/q40";
    public static readonly QEND:string = "assessment/end";
    
    public static readonly ANALYSIS_RESULTS:string = 'assessment/analysis/results';
    public static readonly ANALYSIS_OUTCOMES:string = 'assessment/analysis/outcomes';
    public static readonly ANALYSIS_CARDS_COMBINED:string = 'assessment/analysis/cards/combined';
    public static readonly ANALYSIS_CARDS_GROUPED:string = 'assessment/analysis/cards/grouped';
    public static readonly ANALYSIS_CARDS_VALUES:string = 'assessment/analysis/cards/values';
    public static readonly ANALYSIS_CARDS_CAREERVALUES:string = 'assessment/analysis/cards/careerValues';
    public static readonly ANALYSIS_CARDS_VALUESMEANINGS:string = 'assessment/analysis/cards/valuesMeanings';
    public static readonly ANALYSIS_CARDS_SKILLS:string = 'assessment/analysis/cards/skills';
    public static readonly ANALYSIS_CARDS_SKILLSII:string = 'assessment/analysis/cards/skillsII';
    public static readonly ANALYSIS_CARDS_INTERESTS:string = 'assessment/analysis/cards/interests';
    public static readonly ANALYSIS_CARDS_INTERESTSII:string = 'assessment/analysis/cards/interestsII';
    public static readonly ANALYSIS_CARDS_OCCUPATIONS:string = 'assessment/analysis/cards/occupational';
    public static readonly ANALYSIS_CARDS_SPECIALISED:string = 'assessment/analysis/cards/specialised';
    public static readonly ANALYSIS_CARDS_ARTS:string = 'assessment/analysis/cards/arts';
    public static readonly ANALYSIS_CARDS_TRADES:string = 'assessment/analysis/cards/trades';
    public static readonly ANALYSIS_CARDS_SCIENCE:string = 'assessment/analysis/cards/science';
    public static readonly ANALYSIS_CARDS_HELPING:string = 'assessment/analysis/cards/helping';
    public static readonly ANALYSIS_CARDS_ENGINEERING:string = 'assessment/analysis/cards/engineering';
    public static readonly ANALYSIS_CARDS_BUSINESS:string = 'assessment/analysis/cards/business';
    public static readonly ANALYSIS_CARDS_CREATIVITY:string = 'assessment/analysis/cards/creativity';

    public static readonly ANALYSIS_CARDS_ARTS_EX:string = 'assessment/analysis/cards/artsSingle';
    public static readonly ANALYSIS_CARDS_BUSINESS_EX:string = 'assessment/analysis/cards/businessSingle';
    public static readonly ANALYSIS_CARDS_CREATIVITY_EX:string = 'assessment/analysis/cards/creativitySingle';
    public static readonly ANALYSIS_CARDS_TRADES_EX:string = 'assessment/analysis/cards/tradesSingle';
    public static readonly ANALYSIS_CARDS_SCIENCE_EX:string = 'assessment/analysis/cards/scienceSingle';
    public static readonly ANALYSIS_CARDS_HELPING_EX:string = 'assessment/analysis/cards/helpingSingle';
    public static readonly ANALYSIS_CARDS_ENGINEERING_EX:string = 'assessment/analysis/cards/engineeringSingle';

    public static readonly CAREERS_RECOMMENDED:string = 'assessment/careers/recommendedCareers';
    public static readonly CAREERS_RECOMMENDED_FIELDS:string = 'assessment/careers/recommendedFields';
    public static readonly CAREERS_CREATIVITY:string = 'assessment/careers/creativity';
    public static readonly CAREERS_HELPING:string = 'assessment/careers/helping';
    public static readonly CAREERS_TRADES:string = 'assessment/careers/trades';

    public static readonly BACKGROUND_INFO:string = 'assessment/background';

    public static readonly IA_VALUES_INTRO: string = 'ia/values/intro';
    public static readonly IA_VALUES_CARDS: string = 'ia/values/cards';
    public static readonly IA_VALUES_LEAST: string = 'ia/values/leastResults';
    public static readonly IA_VALUES_MOST: string = 'ia/values/mostResults';
    public static readonly IA_VALUES_MEANINGS: string = 'ia/values/meanings';
    public static readonly IA_VALUES_UNDERSTANDING: string = 'ia/values/understandings';
    public static readonly IA_VALUES_PURPOSE: string = 'ia/values/purpose';
    public static readonly IA_VALUES_PREFERENCES: string = 'ia/values/preferences';

    public static readonly IA_SKILLS_INTRO: string = 'ia/skills/intro';
    public static readonly IA_SKILLS_CARDS: string = 'ia/skills/cards';
    public static readonly IA_SKILLS_MOST: string = 'ia/skills/mostResults';
    public static readonly IA_SKILLS_TYPE: string = 'ia/skills/types';
    public static readonly IA_SKILLS_PREFERRED: string = 'ia/skills/preferred';

    public static readonly IA_INTERESTS_INTRO: string = 'ia/interests/intro';
    public static readonly IA_INTERESTS_CARDS: string = 'ia/interests/cards';
    public static readonly IA_INTERESTS_MOST: string = 'ia/interests/mostResults';
    public static readonly IA_INTERESTS_THEMES: string = 'ia/interests/themes';
}

/*
 This only exists so that views can access the values of the variables.
*/

export class RoutePaths
{
    public USER_REGISTER:string = Routes.USER_REGISTER;
    public USER_PROFILE:string = Routes.USER_PROFILE;
    public USER:string = Routes.USER;
    public USERS:string = Routes.USERS;
    public USER_BILLING:string = Routes.USER_BILLING;
    public USER_ASSESSMENTS:string = Routes.USER_ASSESSMENTS;

    public QUESTIONNAIRE_COPY: string = Routes.QUESTIONNAIRE_COPY;

    public CLIENT_UPDATEDETAILS:string = Routes.CLIENT_UPDATEDETAILS;
    public CLIENT_UPDATEPAGES:string = Routes.CLIENT_UPDATEPAGES;
    public CLIENT_HOME:string = Routes.CLIENT_HOME;
    public CLIENT_LIST:string = Routes.CLIENT_LIST;
    public CLIENT_SENDASSESSMENT:string = Routes.CLIENT_SENDASSESSMENT;

    public CARDS_VALUES:string = Routes.CARDS_VALUES;

    public QUESTIONNAIRE:string = Routes.QUESTIONNAIRE;
    public Q40:string = Routes.Q40;
    public QEND:string = Routes.QEND;
    
    public CARDS_MEANINGS:string = Routes.CARDS_MEANINGS;
    
    public ANALYSIS_RESULTS:string = Routes.ANALYSIS_RESULTS;
    public ANALYSIS_CARDS_COMBINED:string = Routes.ANALYSIS_CARDS_COMBINED;
    public ANALYSIS_CARDS_GROUPED:string = Routes.ANALYSIS_CARDS_GROUPED;
    public ANALYSIS_CARDS_VALUES:string = Routes.ANALYSIS_CARDS_VALUES;
    public ANALYSIS_CARDS_CAREERVALUES:string = Routes.ANALYSIS_CARDS_CAREERVALUES;
    public ANALYSIS_CARDS_VALUESMEANINGS:string = Routes.ANALYSIS_CARDS_VALUESMEANINGS;
    public ANALYSIS_CARDS_SKILLS:string = Routes.ANALYSIS_CARDS_SKILLS;
    public ANALYSIS_CARDS_SKILLSII:string = Routes.ANALYSIS_CARDS_SKILLSII;
    public ANALYSIS_CARDS_INTERESTS:string = Routes.ANALYSIS_CARDS_INTERESTS;
    public ANALYSIS_CARDS_INTERESTSII:string = Routes.ANALYSIS_CARDS_INTERESTSII;
    public ANALYSIS_CARDS_OCCUPATIONS:string = Routes.ANALYSIS_CARDS_OCCUPATIONS;
    public ANALYSIS_CARDS_SPECIALISED:string = Routes.ANALYSIS_CARDS_SPECIALISED;
    public ANALYSIS_CARDS_TRADES:string = Routes.ANALYSIS_CARDS_TRADES;
    public ANALYSIS_CARDS_ARTS:string = Routes.ANALYSIS_CARDS_ARTS;
    public ANALYSIS_CARDS_SCIENCE:string = Routes.ANALYSIS_CARDS_SCIENCE;
    public ANALYSIS_CARDS_HELPING:string = Routes.ANALYSIS_CARDS_HELPING;
    public ANALYSIS_CARDS_ENGINEERING:string = Routes.ANALYSIS_CARDS_ENGINEERING;
    public ANALYSIS_CARDS_BUSINESS:string = Routes.ANALYSIS_CARDS_BUSINESS;
    public ANALYSIS_CARDS_CREATIVITY:string = Routes.ANALYSIS_CARDS_CREATIVITY;

    public ANALYSIS_CARDS_ARTS_EX:string = Routes.ANALYSIS_CARDS_ARTS_EX;
    public ANALYSIS_CARDS_BUSINESS_EX:string = Routes.ANALYSIS_CARDS_BUSINESS_EX;
    public ANALYSIS_CARDS_CREATIVITY_EX:string = Routes.ANALYSIS_CARDS_CREATIVITY_EX;
    public ANALYSIS_CARDS_TRADES_EX:string = Routes.ANALYSIS_CARDS_TRADES_EX;
    public ANALYSIS_CARDS_SCIENCE_EX:string = Routes.ANALYSIS_CARDS_SCIENCE_EX;
    public ANALYSIS_CARDS_HELPING_EX:string = Routes.ANALYSIS_CARDS_HELPING_EX;
    public ANALYSIS_CARDS_ENGINEERING_EX:string = Routes.ANALYSIS_CARDS_ENGINEERING_EX;

    public CAREERS_CREATIVITY: string = Routes.CAREERS_CREATIVITY;
    public CAREERS_HELPING: string = Routes.CAREERS_HELPING;
    public CAREERS_TRADES: string = Routes.CAREERS_TRADES;

    public IA_VALUES_INTRO: string = Routes.IA_VALUES_INTRO;
    public IA_VALUES_CARDS: string = Routes.IA_VALUES_CARDS;
    public IA_VALUES_LEAST: string = Routes.IA_VALUES_LEAST;
    public IA_VALUES_MOST: string = Routes.IA_VALUES_MOST;
    public IA_VALUES_MEANINGS: string = Routes.IA_VALUES_MEANINGS;
    public IA_VALUES_UNDERSTANDING: string = Routes.IA_VALUES_UNDERSTANDING;
    public IA_VALUES_PURPOSE: string = Routes.IA_VALUES_PURPOSE;
    public IA_VALUES_PREFERENCES: string = Routes.IA_VALUES_PREFERENCES;

    public IA_SKILLS_INTRO: string = Routes.IA_SKILLS_INTRO;
    public IA_SKILLS_CARDS: string = Routes.IA_SKILLS_CARDS;
    public IA_SKILLS_MOST: string = Routes.IA_SKILLS_MOST;
    public IA_SKILLS_TYPE: string = Routes.IA_SKILLS_TYPE;
    public IA_SKILLS_PREFERRED: string = Routes.IA_SKILLS_PREFERRED;


    public IA_INTERESTS_INTRO: string = Routes.IA_INTERESTS_INTRO;
    public IA_INTERESTS_CARDS: string = Routes.IA_INTERESTS_CARDS;
    public IA_INTERESTS_MOST: string = Routes.IA_INTERESTS_MOST;
    public IA_INTERESTS_THEMES: string = Routes.IA_INTERESTS_THEMES;

}