import {Router, RouterConfiguration} from "aurelia-router";
import {autoinject} from "aurelia-framework";
import {AuthenticateStep} from 'aurelia-authentication';
import {GlobalState} from "./models/GlobalState";
import {Routes} from './routes';

@autoinject
export class App {
    public router: Router;

    constructor(public globalState: GlobalState) {
    }

    configureRouter(config: RouterConfiguration, router: Router) {
        config.title = 'Insight';
        config.addAuthorizeStep(AuthenticateStep);         // Add a route filter so only authenticated users are authorized to access some routes

        config.map([
            { route: ["",Routes.CLIENT_HOME], moduleId: "./accounts/login", title: "Welcome to Insight by Career Sense", name: "CLIENT_HOME"},

            { route: "administration/menu", moduleId: "./administration/administrationMenu", nav: false, title: "Administration", auth: true, roles: "Administrator", name: "ADMINISTRATION" },
            { route: Routes.USERS, moduleId: "./administration/users", nav: false, title: "Users", name: "USERS" },                          //Note: this is used by the google login/registration process
            { route: Routes.USER.addIdFragment(), moduleId: "./administration/user", nav: false, title: "User", name: "USER" },                          //Note: this is used by the google login/registration process
            { route: Routes.USER_BILLING, moduleId: "./administration/billing", nav: false, title: "Billing Report", auth: true, roles: "Administrator", name: "BILLING" },
            { route: Routes.USER_REGISTER, moduleId: "./administration/registerUser", nav: false, title: "Register User", auth: true, roles: "Administrator", name: "USER_REGISTER" },
            { route: Routes.USER_ASSESSMENTS.addIdFragment(), moduleId: "./administration/assessments", nav: false, title: "User Assessments", auth: true, roles: "Administrator", name: "USER_ASSESSMENTS", href: Routes.USER_ASSESSMENTS.toHRef() },
            { route: Routes.QUESTIONNAIRE_COPY, moduleId: "./administration/assessmentCopy", nav: false, title: "Assessment Copy", auth: true, roles: "Administrator", name: "ASSESSMENT_COPY" },

            { route: Routes.CLIENT_LIST, moduleId: "./accounts/clientResults", href: Routes.CLIENT_LIST.toHRef(), nav: true, title: "Clients", auth: true, roles: "User", name: "CLIENT_LIST"},
            { route: "accounts/registration", moduleId: "./accounts/registration", nav: false, title: "Practitioner Registration" },                                            //Note: this is used by the google login/registration process
            { route: [Routes.CLIENT_ASSESSMENT, "accounts/questionnaire"], moduleId: "./accounts/questionnaire", nav: false, title: "Assessment", name: "ACCOUNTS_ASSESSMENT" },                      //Note: this URL is used by the send questionnaire email link! "accounts/questionnaire", is the old link URL is used by the send questionnaire email link! (old version - uptil May 2020 ..?? Remove Aug 2020?)

            { route: Routes.CLIENT_SENDASSESSMENT, href: Routes.CLIENT_SENDASSESSMENT.toHRef(), moduleId: "./accounts/sendQuestionnaire", nav: false, title: "Send Insight Link", auth: true, roles: "User", name: "CLIENT_SENDASSESSMENT" },
            { route: Routes.CLIENT_UPDATEDETAILS.addIdFragment(), href: Routes.CLIENT_UPDATEDETAILS.toHRef(), moduleId: "./accounts/updateDetail", nav: false, title: "Update Client Details", auth: true, roles: "User", name: "CLIENT_UPDATEDETAILS" },
            { route: Routes.CLIENT_UPDATEPAGES.addIdFragment(), href: Routes.CLIENT_UPDATEPAGES.toHRef(), moduleId: "./accounts/updateQuestionnaire", nav: false, title: "Update Questionnaire", auth: true, roles: "User", name: "CLIENT_UPDATEPAGES" },
            { route: Routes.USER_PROFILE, moduleId: "./accounts/profile", nav: false, title: "Profile", auth: true, roles: "User", name: "USER_PROFILE" },
        
            { route: Routes.QUESTIONNAIRE.addIdFragment(), moduleId: "./riasec/riasecHeader", href: Routes.QUESTIONNAIRE.toHRef(), nav: false, title: "Assessment", auth: true, roles: "User", name: "ASSESSMENT"},         
            { route: Routes.QUESTIONNAIRE_PAYMENT + "/:id/:resultToken/:sessionToken", moduleId: "./accounts/payment", href: Routes.QUESTIONNAIRE_PAYMENT.toHRef(), nav: false, title: "Payment", auth: false, name: "ASSESSMENT_PAYMENT"},         
            { route: Routes.QEND, moduleId: "./riasec/end", nav: false, title: "End of Questions", auth: true, name: "QEND" },
            { route: Routes.ANALYSIS_CARDS_COMBINED.addIdFragment(), moduleId: "./cards/combinedCardSortAnalysis", href: Routes.ANALYSIS_CARDS_COMBINED.toHRef(), nav: false, title: "Combined Analysis",  auth: true, roles: "User", name: "ANALYSIS_CARDS_COMBINED" },
            { route: Routes.ANALYSIS_CARDS_GROUPED.addIdFragment(), moduleId: "./cards/groupedCardSortAnalysis", href: Routes.ANALYSIS_CARDS_GROUPED.toHRef(), nav: false, title: "Grouped Analysis",  auth: true, roles: "User" , name: "ANALYSIS_CARDS_GROUPED"},

            //{ route: Routes.BACKGROUND_INFO, moduleId: "./riasec/background", nav: true, title: "Background", auth: true, roles: "User,QuestionnaireAnalysis" },

            { route: Routes.CARDS_VALUES.addIdFragment(), moduleId: "./cards/valuesCardSort", href: Routes.CARDS_VALUES.toHRef(), nav: true, title: "Values", auth: true, name: "CARDS_VALUES" },
            { route: Routes.ANALYSIS_CARDS_VALUES.addIdFragment(), moduleId: "./cards/valuesCardSortAnalysis", href: Routes.ANALYSIS_CARDS_VALUES.toHRef(), nav: false, title: "Values Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_VALUES" },
            { route: Routes.ANALYSIS_CARDS_CAREERVALUES.addIdFragment(), moduleId: "./cards/valuesCardSortAnalysisII", href: Routes.ANALYSIS_CARDS_CAREERVALUES.toHRef(), nav: false, title: "Values Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_CAREERVALUES"},
            { route: Routes.CARDS_MEANINGS.addIdFragment(), moduleId: "./cards/valuesMeaningsCardSort", href: Routes.CARDS_MEANINGS.toHRef(), nav: true, title: "Meanings", auth: true, roles: "User,QuestionnaireAnalysis", name: "CARDS_MEANINGS" },
            
            { route: Routes.CARDS_SKILLS.addIdFragment(), moduleId: "./cards/skillsCardSort", href: Routes.CARDS_SKILLS.toHRef(), nav: true, title: "Skills", auth: true, name: "CARDS_SKILLS" },
            { route: Routes.ANALYSIS_CARDS_SKILLS.addIdFragment(), moduleId: "./cards/skillsCardSortAnalysis", href: Routes.ANALYSIS_CARDS_SKILLS.toHRef(), nav: false, title: "Skills Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_SKILLS" },
            { route: Routes.ANALYSIS_CARDS_SKILLSII.addIdFragment(), moduleId: "./cards/skillsCardSortAnalysisII", href: Routes.ANALYSIS_CARDS_SKILLSII.toHRef(), nav: false, title: "Skills Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_SKILLSII"},

            { route: Routes.CARDS_INTERESTS.addIdFragment(), moduleId: "./cards/interestsCardSort", href: Routes.CARDS_INTERESTS.toHRef(), nav: true, title: "Interests", auth: true, name: "CARDS_INTERESTS" },
            { route: Routes.ANALYSIS_CARDS_INTERESTS.addIdFragment(), moduleId: "./cards/interestsCardSortAnalysis", href: Routes.ANALYSIS_CARDS_INTERESTS.toHRef(), nav: false, title: "Interests Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_INTERESTS" },
            { route: Routes.ANALYSIS_CARDS_INTERESTSII.addIdFragment(), moduleId: "./cards/interestsCardSortAnalysisII", href: Routes.ANALYSIS_CARDS_INTERESTSII.toHRef(), nav: false, title: "Interests Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_INTERESTSII" },
            { route: Routes.Q40.addIdFragment(), moduleId: "./riasec/riasecQuestions", href: Routes.Q40.toHRef(), nav: true, title: "Q40", auth: true, name: "Q40"},

            { route: "Routes.ANALYSIS_RESULTS", moduleId: "./riasec/riasecResults", nav: true, title: "Q40 Results", auth: true, roles: "User", name: "Q40_MENU" },
            { route: Routes.ANALYSIS_RESULTS.addIdFragment(), moduleId: "./riasec/riasecResults", href: Routes.ANALYSIS_RESULTS.toHRef(), nav: true, title: "Q40 Results", auth: true, roles: "User", name: "ANALYSIS_RESULTS", settings: { parentMenu: "Q40_MENU"}},
            { route: Routes.CAREERS_RECOMMENDED_FIELDS.addIdFragment(), moduleId: "./riasec/predictedCareerFields", href: Routes.CAREERS_RECOMMENDED_FIELDS.toHRef(), nav: true, title: "Q40 Recommended Career Fields", auth: true, roles: "User", name: "CAREERS_RECOMMENDED_FIELDS", settings: { parentMenu: "Q40_MENU" }},
            { route: Routes.CAREERS_RECOMMENDED.addIdFragment(), moduleId: "./riasec/predictedCareers", href: Routes.CAREERS_RECOMMENDED.toHRef(), nav: true, title: "Q40 Recommended Career Options", auth: true, roles: "User", name: "CAREERS_RECOMMENDED", settings: { parentMenu: "Q40_MENU"}},
            { route: Routes.ANALYSIS_RESULTS.addIdFragment(), moduleId: "./riasec/riasecResults", href: Routes.ANALYSIS_RESULTS.toHRef(), nav: true, title: "Q40 Results", auth: true, roles: "QuestionnaireAnalysis", name: "ANALYSIS_RESULTS_USER"},
            { route: Routes.CARDS_STATEMENT.addIdFragment(), moduleId: "./cards/userCardSortAnalysis", href: Routes.CARDS_STATEMENT.toHRef(), nav: true, title: "Statement", auth: true, roles: "User,QuestionnaireAnalysis", name: "CARDS_STATEMENT" },

            { route: Routes.CARDS_OCCUPATIONS.addIdFragment(), moduleId: "./cards/occupationalCardSort", href: Routes.CARDS_OCCUPATIONS.toHRef(), nav: true, title: "Occupations", auth: true, roles: "User,QuestionnaireOccupation", name: "CARDS_OCCUPATIONS" },
            { route: Routes.ANALYSIS_CARDS_OCCUPATIONS.addIdFragment(), moduleId: "./cards/occupationalCardSortAnalysis", href: Routes.ANALYSIS_CARDS_OCCUPATIONS.toHRef(), nav: false, title: "Occupations Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_OCCUPATIONS" },
            { route: Routes.ANALYSIS_CARDS_SPECIALISED.addIdFragment(), moduleId: "./cards/specialisedAnalysis", href: Routes.ANALYSIS_CARDS_SPECIALISED.toHRef(), nav: true, title: "Specialised", auth: true, roles: "User", name: "ANALYSIS_CARDS_SPECIALISED" },

            { route: Routes.CARDS_BUSINESS.addIdFragment(), moduleId: "./cards/businessCardSort", href: Routes.CARDS_BUSINESS.toHRef(), nav: true, title: "Business", auth: true, roles: "User,QuestionnaireBusiness", name: "CARDS_BUSINESS" },
            { route: Routes.ANALYSIS_CARDS_BUSINESS.addIdFragment(), moduleId: "./cards/businessCardSortAnalysis", href: Routes.ANALYSIS_CARDS_BUSINESS.toHRef(), nav: false, title: "Business Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_BUSINESS" },
            { route: Routes.ANALYSIS_CARDS_BUSINESS_EX.addIdFragment(), moduleId: "./cards/businessCardSingleAnalysis", href: Routes.ANALYSIS_CARDS_BUSINESS_EX.toHRef(), nav: false, title: "Business Grouped Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_BUSINESS_EX" },

            { route: Routes.CARDS_ENGINEERING.addIdFragment(), moduleId: "./cards/engineeringCardSort", href: Routes.CARDS_ENGINEERING.toHRef(), nav: true, title: "Engineering", auth: true, roles: "User,QuestionnaireEngineering", name: "CARDS_ENGINEERING" },
            { route: Routes.ANALYSIS_CARDS_ENGINEERING.addIdFragment(), moduleId: "./cards/engineeringCardSortAnalysis", href: Routes.ANALYSIS_CARDS_ENGINEERING.toHRef(), nav: false, title: "Engineering Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_ENGINEERING" },
            { route: Routes.ANALYSIS_CARDS_ENGINEERING_EX.addIdFragment(), moduleId: "./cards/engineeringCardSingleAnalysis", href: Routes.ANALYSIS_CARDS_ENGINEERING_EX.toHRef(), nav: false, title: "Engineering Grouped Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_ENGINEERING_EX"},

            { route: Routes.CARDS_HELPING.addIdFragment(), moduleId: "./cards/helpingCardSort", href: Routes.CARDS_HELPING.toHRef() , nav: true, title: "Helping", auth: true, roles: "User,QuestionnaireHelping", name: "CARDS_HELPING"},
            { route: Routes.ANALYSIS_CARDS_HELPING.addIdFragment(), moduleId: "./cards/helpingCardSortAnalysis", href: Routes.ANALYSIS_CARDS_HELPING.toHRef(), nav: false, title: "Helping Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_HELPING" },
            { route: Routes.ANALYSIS_CARDS_HELPING_EX.addIdFragment(), moduleId: "./cards/helpingCardSingleAnalysis", href: Routes.ANALYSIS_CARDS_HELPING_EX.toHRef(), nav: false, title: "Helping Grouped Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_HELPING_EX" },
            { route: Routes.CAREERS_HELPING.addIdFragment(), moduleId: "./cards/helpingCardSortCareersAnalysis", href: Routes.CAREERS_HELPING.toHRef(), nav: false, title: "Helping Careers", auth: true, roles: "User,QuestionnaireAnalysis", name: "CAREERS_HELPING" },

            { route: Routes.CARDS_SCIENCE.addIdFragment(), moduleId: "./cards/scienceCardSort", href: Routes.CARDS_SCIENCE.toHRef(), nav: true, title: "Science", auth: true, roles: "User,QuestionnaireScience", name: "CARDS_SCIENCE" },
            { route: Routes.ANALYSIS_CARDS_SCIENCE.addIdFragment(), moduleId: "./cards/scienceCardSortAnalysis", href: Routes.ANALYSIS_CARDS_SCIENCE.toHRef(), nav: false, title: "Science Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_SCIENCE" },
            { route: Routes.ANALYSIS_CARDS_SCIENCE_EX.addIdFragment(), moduleId: "./cards/scienceCardSingleAnalysis", href: Routes.ANALYSIS_CARDS_SCIENCE_EX.toHRef(), nav: false, title: "Science Grouped Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_SCIENCE_EX" },
       
            { route: Routes.CARDS_CREATIVITY.addIdFragment(), moduleId: "./cards/creativityCardSort", href: Routes.CARDS_CREATIVITY.toHRef(), nav: true, title: "Creativity", auth: true, roles: "User,QuestionnaireCreativity", name: "CARDS_CREATIVITY" },
            { route: Routes.ANALYSIS_CARDS_CREATIVITY.addIdFragment(), moduleId: "./cards/creativityCardSortAnalysis", href: Routes.ANALYSIS_CARDS_CREATIVITY.toHRef(), nav: false, title: "Creativity Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_CREATIVITY"},
            { route: Routes.ANALYSIS_CARDS_CREATIVITY_EX.addIdFragment(), moduleId: "./cards/creativityCardSingleAnalysis", href: Routes.ANALYSIS_CARDS_CREATIVITY_EX.toHRef(), nav: false, title: "Creativity Grouped Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_CREATIVITY_EX" },
            { route: Routes.CAREERS_CREATIVITY.addIdFragment(), moduleId: "./cards/creativityCardSortCareers", href: Routes.CAREERS_CREATIVITY.toHRef(), nav: false, title: "Creativity Careers", auth: true, roles: "User,QuestionnaireAnalysis", name: "CAREERS_CREATIVITY" },
        
            { route: Routes.CARDS_TRADES.addIdFragment(), moduleId: "./cards/tradesCardSort", href: Routes.CARDS_TRADES.toHRef(), nav: true, title: "Pre-Degree", auth: true, roles: "User,QuestionnaireTrades", name: "CARDS_TRADES" },
            { route: Routes.ANALYSIS_CARDS_TRADES.addIdFragment(), moduleId: "./cards/tradesCardSortAnalysis", href: Routes.ANALYSIS_CARDS_TRADES.toHRef(), nav: false, title: "Pre-Degree Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_TRADES" },
            { route: Routes.ANALYSIS_CARDS_TRADES_EX.addIdFragment(), moduleId: "./cards/tradesCardSingleAnalysis", href: Routes.ANALYSIS_CARDS_TRADES_EX.toHRef(), nav: false, title: "Pre-Degree Grouped Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_TRADES_EX" },
            { route: Routes.CAREERS_TRADES.addIdFragment(), moduleId: "./cards/tradesCardSortCareers", href: Routes.CAREERS_TRADES.toHRef(), nav: false, title: "Pre-Degree Careers", auth: true, roles: "User,QuestionnaireAnalysis", name: "CAREERS_TRADES" },
        
            { route: Routes.CARDS_ARTS.addIdFragment(), moduleId: "./cards/artsCardSort", href: Routes.CARDS_ARTS.toHRef(), nav: true, title: "Arts & Soc Science", auth: true, roles: "User,QuestionnaireArts", name: "CARDS_ARTS" },
            { route: Routes.ANALYSIS_CARDS_ARTS.addIdFragment(), moduleId: "./cards/artsCardSortAnalysis", href: Routes.ANALYSIS_CARDS_ARTS.toHRef(), nav: false, title: "Arts Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name:  "ANALYSIS_CARDS_ARTS" },
            { route: Routes.ANALYSIS_CARDS_ARTS_EX.addIdFragment(), moduleId: "./cards/artsCardSingleAnalysis", href: Routes.ANALYSIS_CARDS_ARTS_EX.toHRef(), nav: false, title: "Arts Grouped Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_ARTS_EX" },

            { route: Routes.ANALYSIS_OUTCOMES.addIdFragment(), moduleId: "./riasec/outcomes", href: Routes.ANALYSIS_OUTCOMES.toHRef(), nav: true, title: "Outcomes", auth: true, roles: "User", name: "ANALYSIS_OUTCOMES" },
            
            { route: Routes.IA_VALUES_INTRO.addIdFragment(), moduleId: "./ia/valuesIntro", href: Routes.IA_VALUES_INTRO.toHRef(), nav: false, title: "Values Intro", auth: true, roles: "User", name: "IA_VALUES_INTRO" },
            { route: Routes.IA_VALUES_CARDS.addIdFragment(), moduleId: "./ia/valuesCards", href: Routes.IA_VALUES_CARDS.toHRef(), nav: false, title: "Values Card Sort", auth: true, roles: "User", name: "IA_VALUES_CARDS" },
            { route: Routes.IA_VALUES_LEAST.addIdFragment(), moduleId: "./ia/valuesLeast", href: Routes.IA_VALUES_LEAST.toHRef(), nav: false, title: "Values Least Important", auth: true, roles: "User", name: "IA_VALUES_LEAST" },
            { route: Routes.IA_VALUES_MOST.addIdFragment(),  moduleId: "./ia/valuesMost", href: Routes.IA_VALUES_MOST.toHRef(), nav: false, title: "Values Most Important", auth: true, roles: "User", name: "IA_VALUES_MOST" },
            { route: Routes.IA_VALUES_MEANINGS.addIdFragment(),  moduleId: "./ia/valuesMeaningsIntro", href: Routes.IA_VALUES_MEANINGS.toHRef(), nav: false, title: "Values Meanings", auth: true, roles: "User", name: "IA_VALUES_MEANINGS" },
            { route: Routes.IA_VALUES_UNDERSTANDING.addIdFragment(),  moduleId: "./ia/valuesUnderstandingsIntro", href: Routes.IA_VALUES_UNDERSTANDING.toHRef(), nav: false, title: "Values Understanding", auth: true, roles: "User", name: "IA_VALUES_UNDERSTANDING" },

            { route: Routes.IA_VALUES_PURPOSE.addIdFragment(),  moduleId: "./ia/valuesPurpose", href: Routes.IA_VALUES_PURPOSE.toHRef(), nav: false, title: "Values Meanings", auth: true, roles: "User", name: "IA_VALUES_PURPOSE" },
            { route: Routes.IA_VALUES_PREFERENCES.addIdFragment(),  moduleId: "./ia/valuesPreferences", href: Routes.IA_VALUES_PREFERENCES.toHRef(), nav: false, title: "Values Understanding", auth: true, roles: "User", name: "IA_VALUES_PREFERENCES" },

            { route: Routes.IA_SKILLS_INTRO.addIdFragment(),  moduleId: "./ia/skillsIntro", href: Routes.IA_SKILLS_INTRO.toHRef(), nav: false, title: "Skills Intro", auth: true, roles: "User", name: "IA_SKILLS_INTRO" },
            { route: Routes.IA_SKILLS_CARDS.addIdFragment(),  moduleId: "./ia/skillsCards", href: Routes.IA_SKILLS_CARDS.toHRef(), nav: false, title: "Skills Card Sort", auth: true, roles: "User", name: "IA_SKILLS_CARDS" },
            { route: Routes.IA_SKILLS_MOST.addIdFragment(),  moduleId: "./ia/skillsMost", href: Routes.IA_SKILLS_MOST.toHRef(), nav: false, title: "Skills Most Important", auth: true, roles: "User", name: "IA_SKILLS_MOST" },
            { route: Routes.IA_SKILLS_TYPE.addIdFragment(),  moduleId: "./ia/skillsTypes", href: Routes.IA_SKILLS_TYPE.toHRef(), nav: false, title: "Skill Types", auth: true, roles: "User", name: "IA_SKILLS_TYPE" },
            { route: Routes.IA_SKILLS_PREFERRED.addIdFragment(),  moduleId: "./ia/skillsPreferred", href: Routes.IA_SKILLS_PREFERRED.toHRef(), nav: false, title: "Skills Preferred", auth: true, roles: "User", name: "IA_SKILLS_PREFERRED" },

            { route: Routes.IA_INTERESTS_INTRO.addIdFragment(),  moduleId: "./ia/interestsIntro", href: Routes.IA_INTERESTS_INTRO.toHRef(), nav: false, title: "Interests Intro", auth: true, roles: "User", name: "IA_INTERESTS_INTRO" },
            { route: Routes.IA_INTERESTS_CARDS.addIdFragment(),  moduleId: "./ia/interestsCards", href: Routes.IA_INTERESTS_CARDS.toHRef(), nav: false, title: "Interests Card Sort", auth: true, roles: "User", name: "IA_INTERESTS_CARDS" },

            { route: "logout", moduleId: "./accounts/logout", nav: true, auth: true, title: "Logout", name: "logout"}
        ]);
        config.mapUnknownRoutes("./accounts/login");

        this.router = router;
    }
}
