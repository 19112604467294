import {bindable, autoinject} from 'aurelia-framework';
import {SecurityRoles} from '../../models/SecurityRoles';
import {DOM} from 'aurelia-pal'

@autoinject
export class NextPageHandlerCustomElement {
    @bindable 
    public isEndReached: boolean = null;
    public isVisible: boolean = null;
    public evt: CustomEvent;

    constructor(private readonly element: Element, public securityRoles: SecurityRoles) {
        this.evt = DOM.createCustomEvent('oncontinue', { bubbles: true, cancelable: true, detail: { isQuestionnaireUser: this.securityRoles.isQuestionnaireUser} });
    }

    public close(): void {
        this.isVisible = false;
        this.isEndReached = false;
        this.element.dispatchEvent(this.evt);
    }

    public isEndReachedChanged(value: boolean, oldValue: any)
    {
         // We only want to stop the user if they have full access - ie sitting with a client in a session.
         if (value != oldValue && value)
         {
            if (this.securityRoles.isQuestionnaireUser)
            {
                // Don't present the dialog, continue with the processing as normal. Fire off the event to continue ...
                this.close();
                return;
            }

            // Stop the user, present the dialog.
            this.isVisible = true;
         }
    }
}

