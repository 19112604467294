import {computedFrom} from 'aurelia-framework';
import {RiasecQuestionnaire} from '../models/RiasecQuestionnaire';

export interface IRiasecQuestionnaireInfo { 
    careerA: string;
    careerB: string;
    careerRiasecA: string;
    careerRiasecB: string;
    notes: string;
}

export class RiasecQuestionnaireInfo implements IRiasecQuestionnaireInfo {
    public id: string;
    public oid: number;
    public type: string;
    public version: string;
    public firstname: string;
    public surname: string;
    public isLinkSent: boolean;
    public isPaid: boolean;
    public isPaymentRequired: boolean;
    public isComplete: boolean;
    public appointment: Date;
    public apptmnt: string;
    public linkSentAt: Date;
    public career: string;
    public careerA: string;
    public careerB: string;
    public careerRiasecA: string;
    public careerRiasecB: string;
    public notes: string;
    public createdAt: string;
    public changedAt: string;
    public questionCount: number;
    public css:string;
    public qSets: string;
    public isDeleted: boolean = false;
    public name: string;

    constructor() {
    }

    public static toQuestionnaireInfo(obj: any): RiasecQuestionnaireInfo {
        var qi = new RiasecQuestionnaireInfo();
        qi.id = obj.id;
        qi.oid = obj.oid;
        qi.type = obj.type;
        qi.version = obj.version;
        qi.firstname = obj.firstname;
        qi.surname = obj.surname;
        qi.name = qi.firstname + ' ' + qi.surname;
        qi.isPaid = obj.isPaid;
        qi.isPaymentRequired = obj.isPaymentRequired;
        qi.isLinkSent = obj.isLinkSent;
        qi.isComplete = obj.isComplete;
        qi.linkSentAt = obj.linkSentAt;
        qi.appointment = (obj.appointment) ? new Date(obj.appointment) : null;
        qi.apptmnt = obj.apptmnt;
        qi.careerA = obj.careerA;
        qi.careerB = obj.careerB;
        qi.careerRiasecA = obj.careerRiasecA;
        qi.careerRiasecB = obj.careerRiasecB;
        qi.notes = obj.notes;
        qi.createdAt = obj.createdAt;
        qi.changedAt = obj.completedAt;
        qi.questionCount = obj.questionCount;
        qi.career = obj.careerA > ' ' ? obj.careerA : "________?";
        qi.qSets = '';

        if (obj.isOccupationsAnswered)  qi.qSets = qi.qSets + 'O';
        if (obj.isHelpingAnswered)  qi.qSets = qi.qSets + 'H';
        if (obj.isBusinessAnswered)  qi.qSets = qi.qSets + 'B';
        if (obj.isEngineerAnswered)  qi.qSets = qi.qSets + 'E';
        if (obj.isScienceAnswered)  qi.qSets = qi.qSets + 'S';
        if (obj.isCreativityAnswered)  qi.qSets = qi.qSets + 'C';
        if (obj.isTradesAnswered)  qi.qSets = qi.qSets + 'P';
        if (obj.isArtsAnswered)  qi.qSets = qi.qSets + 'A';
        return qi;
    }

    public toQuestionnaire(): RiasecQuestionnaire {
        var qi = new RiasecQuestionnaire();
        qi.id = this.id;
        qi.type = this.type;
        qi.version = this.version;
        qi.firstname = this.firstname;
        qi.surname = this.surname;
        qi.name = qi.firstname + ' ' + qi.surname;
        qi.appointment = this.appointment;
        qi.isPaid = this.isPaid;
        qi.isPaymentRequired = this.isPaymentRequired;
        qi.isComplete = this.isComplete;
        qi.careerA = this.careerA;
        qi.careerB = this.careerB;
        qi.careerRiasecA = this.careerRiasecA;
        qi.careerRiasecB = this.careerRiasecB;
        qi.notes = this.notes;
        qi.loaded = false;
        return qi;
    }

    @computedFrom("name")   
    public get isNotActualAssessment(): boolean
    {
        return this.name.search(/(test|example|study)/i) != -1;
    }
}     