import {inject} from 'aurelia-framework';
import {Endpoint, Rest} from 'aurelia-api';
import {GlobalState} from '../models/GlobalState';
import {RiasecQuestionnaire} from '../models/RiasecQuestionnaire';
import {RiasecQuestionAnswer} from '../models/RiasecQuestionAnswer';
import {RiasecQuestionnaireInfo, IRiasecQuestionnaireInfo} from '../models/RiasecQuestionnaireInfo';
import {RiasecResult, IRiasecResults} from '../models/RiasecResult';
import {ScaleResult, ICardsScaleResult} from '../models/ScaleResult';
import {CardSortItem, ICardSortItems, selectType} from '../models/CardSortItem';
import {CardSortItemEx, ICardSortItemExList} from '../models/CardSortItemEx';
import {CardSortResult, ICardSortResult} from '../models/CardSortResult';
import {CareerTheme, ICareerThemes} from '../models/CareerTheme';
import {BackgroundInfo, IBackgroundInfo } from '../models/backgroundInfo';
import {CardOptions} from '../models/CardOptions';

const endOfQuestionnaireReachedUri: string = 'end';
const initialiseQuestionnaireUri: string = 'riasec';
const questionnaireUri: string = 'riasec/';
const backgroundUri: string = 'background/';
const riasecAnalysisUri: string = 'analysis/riasec/'; 
const cardsRiasecAnalysisUri: string = 'analysis/cards/';
const cardsGroupedAnalysisUri: string = 'analysis/cards/grouped/'; 
const cardsSpecialisedAnalysisUri: string = 'analysis/cards/specialised/';
const cardsUserTitlesUri: string = 'analysis/cards/usertitles/'; 
const cardsSingleAnalysisUri: string = 'analysis/cards/single/'; 
const listUri: string = 'listRecent/';
const loadUserAssessmentsUri: string = 'listUsersAssessments/';
const cardDataUri: string = 'cards/';
const cardDataExUri: string = 'cardmeanings/';
const cardDataOptionsUri: string = 'cards/options/';
const careerThemeReportReportUri: string = 'analysis/cards/careerthemes/'; 
const careersAnalysisReportUri: string = 'analysis/cards/careers/'; 
const cardsAnalysisReportUri: string = 'analysis/cards/'; 
const careerReportUri: string = 'analysis/careerreport/'; 
const saveQuestionAnswersUri: string = 'riasec/';
const saveCareerInfoUri: string = 'careers/';
const saveCardAnswerUri: string = 'cards/';

@inject(Endpoint.of("questions"))
export class QuestionServer {

    constructor(private endpoint: Rest) {
        (this.endpoint.defaults as any).retries = 3;
    }

    public initialiseQuestionnaire(person: any): Promise<RiasecQuestionnaire> {
        return this.endpoint.post(initialiseQuestionnaireUri, person)
          .then(data => {
                let questionnaire = new RiasecQuestionnaire() 
                questionnaire.toQuestionnaire(data);
                return questionnaire;
            });
    }

    public loadQuestionnaire(questionnaireId: string, globalState: GlobalState): Promise<RiasecQuestionnaire> {
        return this.endpoint.find(questionnaireUri + questionnaireId)
            .then(data => {
                let questionnaire = new RiasecQuestionnaire();
                questionnaire.toQuestionnaire(data);
                globalState.assignNames(data);
                globalState.questionnaire = questionnaire;
                return questionnaire;
            });
    }

    public loadBackgroundInfo(questionnaireId: string, info: IBackgroundInfo): Promise<BackgroundInfo> {
        return this.endpoint.find(backgroundUri + questionnaireId)
            .then(data => {
                let background = BackgroundInfo.toBackgroundInfo(data);
                info.education = data.education;
                info.work = data.work;
                info.leisure = data.leisure;
                info.genogram = data.genogram;
        
                return background;
            });
    }

    public loadCareerReport(questionnaireId: string): Promise<Blob> {
        return this.endpoint.client.get(careerReportUri + questionnaireId)
            .then(response => response.blob())
            .then(blob => {
                let file = new Blob([blob], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
                return file;
            });
    }

    public loadUsersAssessments(userId: string): Promise<RiasecQuestionnaireInfo[]> {
        return this.endpoint.find(loadUserAssessmentsUri + userId)
        .then(data => {
            let qs = data.map(RiasecQuestionnaireInfo.toQuestionnaireInfo);
            for (let i = 0; i < qs.length; i++)
            {
                let rem = i % 4;
                qs[i].css = (rem < 2) ? 'oddline' : 'evenline';
            }

            return qs;
        });
    }
    
    public loadQuestionnaireList(displayAll: boolean): Promise<RiasecQuestionnaireInfo[]> {
        return this.endpoint.find(listUri + displayAll)
            .then(data => {
                let qs = data.map(RiasecQuestionnaireInfo.toQuestionnaireInfo);
                for (let i = 0; i < qs.length; i++)
                {
                    let rem = i % 4;
                    qs[i].css = (rem < 2) ? 'oddline' : 'evenline';
                }

                return qs;
            });
    }

    public loadRiasecAnalysis(questionnaireId: string, globalState: GlobalState, info: IRiasecQuestionnaireInfo, results: IRiasecResults ): Promise<any> {
        return this.endpoint.find(riasecAnalysisUri + questionnaireId)
            .then(obj => {
                globalState.assignCommonData(obj);
                QuestionServer.loadIRiasecQuestionnaireInfo(info, obj);
                QuestionServer.loadIRiasecResults(results, obj);
                return obj;
            });
    }

    public loadCardsRiasecAnalysis(questionnaireId: string, globalState: GlobalState, info: IRiasecQuestionnaireInfo, results: IRiasecResults ): Promise<any> {
        return this.endpoint.find(cardsRiasecAnalysisUri + questionnaireId)
            .then(obj => {
                globalState.assignCommonData(obj);
                QuestionServer.loadIRiasecQuestionnaireInfo(info, obj);
                QuestionServer.loadIRiasecResults(results, obj);

                return obj;
            });
    }
    
    public loadSpecialisedAnalysis(questionnaireId: string, globalState: GlobalState, info: IRiasecQuestionnaireInfo, results: IRiasecResults ): Promise<any> {
        return this.endpoint.find(cardsSpecialisedAnalysisUri + questionnaireId)
            .then(obj => {
                globalState.assignCommonData(obj);
                QuestionServer.loadIRiasecQuestionnaireInfo(info, obj);
                QuestionServer.loadIRiasecResults(results, obj);

                return obj;
            });
    }

    public loadCardsGroupedAnalysis(questionnaireId: string, setIdFragment: string, globalState: GlobalState, results: ICardsScaleResult ): Promise<any> {
        let endpointMethod: string ="";
        switch (setIdFragment)
        {
            case "Grouped":
                endpointMethod = cardsGroupedAnalysisUri + questionnaireId;
                break;
            default:
                endpointMethod = cardsSingleAnalysisUri + questionnaireId + "/" + setIdFragment;
                break;
        }
        
        return this.loadGroupedAnalysis(endpointMethod, globalState, results);
    }

    public loadUserCardTitlesAnalysis(questionnaireId: string, option: string, globalState: GlobalState, results: ICardsScaleResult ): Promise<any> {
        let endpointMethod: string = cardsUserTitlesUri + questionnaireId + "/" + option;
        return this.loadGroupedAnalysis(endpointMethod, globalState, results);
    }

    private loadGroupedAnalysis(endpointMethod: string, globalState: GlobalState, results: ICardsScaleResult): Promise<any>
    {
        return this.endpoint.find(endpointMethod)
            .then(obj => {
                globalState.assignCommonData(obj);
                this.readScaleResults(obj, results);
            });
    }

    private readScaleResults(obj: any, results: ICardsScaleResult): void {
        let scaleResults = obj.groupedScales.map(ScaleResult.toGroupedScaleResult);
        results.notes = obj.notes;
        results.scaleResults = scaleResults;
        results.fixupResultsFromScaleResults();
        if (obj.topValues) {
            results.topValues = ScaleResult.toGroupedScaleResult(obj.topValues);
            results.topValues.scaleCode = results.topValues.scaleCode;
        }
    }

    private static loadIRiasecQuestionnaireInfo(info: IRiasecQuestionnaireInfo, obj: any): void {
        info.careerA = obj.careerA;
        info.careerB = obj.careerB;
        info.careerRiasecA = obj.careerRiasecA;
        info.careerRiasecB = obj.careerRiasecB;
        info.notes = obj.notes;
    }

    private static loadIRiasecResults(results: IRiasecResults, obj: any): void {
        results.riasecResults = obj.riasecValues.map(RiasecResult.toRiasecResult);
        results.riasecPercent = obj.riasecPercent.map(RiasecResult.toRiasecResult);
        results.scaleResults = obj.scaleValues.map(ScaleResult.toScaleResult);
        results.rResults = obj.rValues.map(ScaleResult.toScaleResult);
        results.iResults = obj.iValues.map(ScaleResult.toScaleResult);
        results.aResults = obj.aValues.map(ScaleResult.toScaleResult);
        results.sResults = obj.sValues.map(ScaleResult.toScaleResult);
        results.eResults = obj.eValues.map(ScaleResult.toScaleResult);
        results.cResults = obj.cValues.map(ScaleResult.toScaleResult);
        results.careers = obj.careers;
        results.careerLevels = obj.careerLevels;
        results.cardSorts = obj.specialisedAnalysis;
    }


    public loadCareerThemeReport(questionnaireId: string, setIdFragment: string, globalState: GlobalState, results: ICareerThemes ): Promise<any> {
        return this.endpoint.find(careerThemeReportReportUri + questionnaireId + "/" + setIdFragment)
            .then(obj => {
                results.themedCareers = obj.map(CareerTheme.toCareerTheme);
            });
    }
   

    public loadCardData(questionnaireId: string, setIdFragment: string, globalState: GlobalState, model: ICardSortItems ): Promise<any> {
        return this.endpoint.find(cardDataUri + questionnaireId + '/2/'+ setIdFragment)
            .then(obj => {
                    if (obj.cards) {
                        globalState.assignNames(obj);

                        let results = obj.cards.map(CardSortItem.toCardSortItem);
                        results.forEach(card => {
                            switch (card.cardPile) {
                                case selectType.isNot:
                                    model.notList.push(card);
                                    break;
                                case selectType.isSlight:
                                    model.slightList.push(card);
                                    break;
                                case selectType.isMaybe:
                                    model.maybeList.push(card);
                                    break;
                                case selectType.isVery:
                                    model.veryList.push(card);
                                    break;
                                case selectType.isDefinite:
                                    model.definiteList.push(card);
                                    break;
                                default:
                                    model.cardList.push(card);
                                    break;
                            }
                        });
                    }

                    return obj;
                });
    }

    public loadCardMeaningsData(questionnaire: RiasecQuestionnaire, model: ICardSortItemExList ): Promise<any> {
        return this.endpoint.find(cardDataExUri +  questionnaire.id + '/2/1')
            .then(obj => {
                    if (obj) {
                        questionnaire.isMostMeaningsOnly = (obj.isMostMeaningsOnly == true);
                        let result = obj.items.map(CardSortItemEx.toCardSortItemEx);
                        model.cardList = result;
                        return result;
                    }

                    return obj;
                });
    }

    public loadCardAnalysisResults(questionnaireId: string, setIdFragment: string, globalState: GlobalState, model: ICardSortResult ): Promise<any> {
        return this.endpoint.find(cardsAnalysisReportUri + questionnaireId + '/2/'+ setIdFragment)
            .then(obj => {
                        if (obj.cards) {
                            globalState.assignCommonData(obj);

                            let cardList = <CardSortItem[]>obj.cards.map(CardSortItem.toCardSortItem);
                            // Perform a sort so that in the card analysis it displays in alphabetical order.
                            cardList.sort((a, b) => 
                                { 
                                    if (a.description < b.description) return -1;
                                    if (a.description > b.description) return 1;
                                    return 0;
                                });
                            let definiteIndex: number = 0,  veryIndex: number = 0, maybeIndex: number = 0, slightlndex: number = 0, notIndex: number = 0;
                            let results = model.cardsortResults;
                            let top2Results = model.cardsortResultsTop2;
                            let top3Results = model.cardsortResultsTop3;
                            cardList.forEach((card) => {
                                switch (card.cardPile) {
                                    case selectType.isNot:
                                        if (notIndex >= results.length) {
                                            results.push(new CardSortResult());
                                        }
                                        results[notIndex].not = card.description;
                                        notIndex++;
                                        break;
                                    case selectType.isSlight:
                                        if (slightlndex >= results.length) {
                                            results.push(new CardSortResult());
                                        }
                                        results[slightlndex].slight = card.description;
                                        slightlndex++;
                                        break;
                                    case selectType.isMaybe:
                                        if (maybeIndex >= results.length) {
                                            results.push(new CardSortResult());
                                        }
                                        if (maybeIndex >= top3Results.length) {
                                            top3Results.push(new CardSortResult());
                                        }
                                        results[maybeIndex].maybe = card.description;
                                        top3Results[maybeIndex].maybe = card.description;
                                        maybeIndex++;
                                        break;
                                    case selectType.isVery:
                                        if (veryIndex >= results.length) {
                                            results.push(new CardSortResult());
                                        }
                                        if (veryIndex >= top2Results.length) {
                                            top2Results.push(new CardSortResult());
                                        }
                                        if (veryIndex >= top3Results.length) {
                                            top3Results.push(new CardSortResult());
                                        }
                                        results[veryIndex].very = card.description;
                                        top2Results[veryIndex].very = card.description;
                                        top3Results[veryIndex].very = card.description;
                                        veryIndex++;
                                        break;
                                    case selectType.isDefinite:
                                        if (definiteIndex >= results.length) {
                                            results.push(new CardSortResult());
                                        }
                                        if (definiteIndex >= top2Results.length) {
                                            top2Results.push(new CardSortResult());
                                        }
                                        if (definiteIndex >= top3Results.length) {
                                            top3Results.push(new CardSortResult());
                                        }
                                        results[definiteIndex].definite = card.description;
                                        top2Results[definiteIndex].definite = card.description;
                                        top3Results[definiteIndex].definite = card.description;
                                        definiteIndex++;
                                        break;
                                    default:
                                        break;
                                }
                            });

                            model.displayResults = results;
                            model.definiteItemsCount = definiteIndex;
                            model.analysisCounts = obj.analysisCounts;
                            model.analysis = obj.analysis;
                    }

                    return obj;
                });
    }

    /**
     * Get the career analysis based on the clients helping cards answers. Not filtered.
     *
     * @param {string}              questionnaireId   The questionnaire identifier for the clients session
     * @param {GlobalState}         globalState       A reference to the common object holding the sessions persistent state
     * @param {ICardSortResult}     model             The data obtained from the server is loaded into this object. Only analysisCounts are populated as a result of this call.
     *
     * @return {Promise<*>|Promise<Error>}            Server response as Object
     */

    public loadCareersAnalysisResults(questionnaireId: string, globalState: GlobalState, model: ICardSortResult ): Promise<any> {
        return this.endpoint.find(careersAnalysisReportUri + questionnaireId)
            .then(obj => {
                        if (obj.cards) {
                            globalState.assignCommonData(obj);
                            let itms: any[] = [];
                            let excItms : any[] = [];
                            let i1: number = 0;
                            let i2: number = 0;
                            obj.analysisCounts.forEach( itm => {
                                if (itm.isExcluded)
                                {
                                    itm.css = i2 % 6 < 3 ? 'oddline' : 'evenline';
                                    excItms[i2++] = itm;
                                }
                                else
                                {
                                    itm.css = i1 % 6 < 3 ? 'oddline' : 'evenline';
                                    itms[i1++] = itm;
                                }
                            });

                            model.analysisCounts = itms;
                            model.analysis = excItms;
                    }

                    return obj;
                });
    }

    /**
     * Persists the answers provided by the user to the database. This is used to persist a single page of Q40 answers (3 at a time).
     *
     * @param {string}                      questionnaireId     The questionnaire identifier for the clients session
     * @param {string}                      seriesId            The type of data being provided 1 = Q40 tytpe answers, 2 = Card sort answers.
     * @param {string}                      setId               The answer set identifier - relates to the question being asked.
     * @param {RiasecQuestionAnswer[]}      answers             The set of 3 answers, ranked that relate to the question asked.
     *
     * @return {Promise<*>|Promise<Error>}            Server response as Object
     */

    public saveQuestionAnswers(questionnaireId: string, seriesId: string, setId: string, answers: RiasecQuestionAnswer[] ): Promise<any | Error>
    { 
        return this.endpoint.update(saveQuestionAnswersUri + questionnaireId + '/'+ seriesId + '/'+ setId, null, answers);
    }

    public deleteAssessment(questionnaireId: string, userId: string ): Promise<any | Error>
    { 
        return this.endpoint.destroyOne(saveQuestionAnswersUri + questionnaireId, userId);
    }

    public saveCardAnswer(questionnaireId: string, seriesId: string, setId: string, card: CardSortItem): Promise<any | Error>
    {
        return this.endpoint.update(saveCardAnswerUri + questionnaireId + '/'+seriesId+'/'+ setId + '/'+ card.id.toString(), null, card);
    }

    public saveCardDataEx(questionnaireId: string, setIdFragment: string, card: CardSortItemEx ): Promise<any | Error> {
        let data = {
            answerText: card.answerText, 
            answer: card.answer, 
            answerCode: null 
        };
        return this.endpoint.update(cardDataExUri + questionnaireId + '/2/'+ setIdFragment + '/'+ card.id.toString(), null, data);
    }

    public saveCardOptions(questionnaireId: string, setIdFragment: string, data: CardOptions): Promise<any | Error> {
        return this.endpoint.update(cardDataOptionsUri + questionnaireId + '/2/'+ setIdFragment, null, data);
    }
    
    public saveBackgroundInfo(questionnaireId: string, info: IBackgroundInfo): Promise<any | Error>
    { 
        return this.endpoint.update(backgroundUri + questionnaireId, null, info);
    }

    public saveCareerInfo(questionnaireId: string, info: IRiasecQuestionnaireInfo): Promise<any | Error>
    { 
        return this.endpoint.update(saveCareerInfoUri + questionnaireId, null, info);
    }

    public saveUserTitles(questionnaireId: string, info: any, results: ICardsScaleResult): Promise<any | Error>
    { 
        return this.endpoint.update(cardsUserTitlesUri + questionnaireId, null, info);
    }

    public endOfQuestionniareReached(questionnaireId: string): Promise<any | Error>
    { 
        return this.endpoint.update(endOfQuestionnaireReachedUri + '/' + questionnaireId);
    }
}

