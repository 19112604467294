import {autoinject} from 'aurelia-framework';
import {AuthService} from 'aurelia-authentication';
import {parseQueryString} from 'aurelia-path';
import {SecurityRoles} from '../models/SecurityRoles';
import {AccountsServer} from '../server/AccountsServer';
import {QuestionServer} from '../server/QuestionServer';
import {TokenServer} from '../server/TokenServer';
import {GlobalState} from '../models/GlobalState';
import {ErrorObject} from '../models/ErrorObject';
import {Router, RouteConfig} from 'aurelia-router';
import {backPage} from '../resources/navigation/NextPage';
import {Routes, RoutePaths} from '../routes';

@autoinject
@backPage()
export class AccountsLogin extends RoutePaths {

    public errorObject: ErrorObject = null;
    public isBusy: boolean = false;
    public registrationToken: string = '';
    public isLoading: boolean = true;

    constructor(protected router: Router, protected globalState: GlobalState, private authService: AuthService, public securityRoles: SecurityRoles, private server: AccountsServer, private questionServer: QuestionServer, private tokenServer: TokenServer) {
        super();
        this.globalState.clearFooterDetails();
    }

    public activate(params: any, route: RouteConfig, navInstruction: any) {
       if (!this.authService.authenticated && !(params.state) && !!window.location.search)
       {
           // due to bugs and the # position in the URL, if we get here with a possible reply from google,
           // then adjust the parameters ...
           params = parseQueryString(window.location.search);
       }

       if (params.state && params.authuser && params.code && params.session_state && params.prompt)
       {
           // then we have a callback query string from google ... attempt to login.
           // Note this happens in a popup window, so we attempt to close it.
            this.server.login(
                { 
                    registrationToken: "registered", 
                    code: params.code,
                    prompt: params.prompt,
                    session_state: params.session_state,
                    redirectUri: this.authService.config.providers.google.redirectUri
                }).then(response => {
                    this.authService.setResponseObject(response);
                })
                .catch(reason => 
                {
                    this.errorObject = new ErrorObject(
                        reason,
                        this,
                        'Google login or validation of the Google credentials failed', 
                        'Try again?');       
                    this.isBusy = false;
                });
       }

       if (navInstruction.fragment.indexOf("logout") >= 0)
       {
           this.authService.logout();
           this.authService.setResponseObject(null);
        }
       else if (this.securityRoles.isQuestionnaireUser)
       {
            // This case happens if the user closes the browser and then comes back to insight by not using the link provided.
            let payload = <any>this.authService.getTokenPayload();
            let token = payload.questionnaireId;  
            let email = payload.sub;               
            let hash  = payload.hash;

            if (!this.globalState.questionnaire    || !this.globalState.questionnaire.loaded
             || !this.globalState.questionnaire.id || !this.globalState.questionnaire.id == token)
            {
                this.questionServer.loadQuestionnaire(token, this.globalState)
                    .then(response => {
                        // Get an updated token from the server as the access profile may have been changed.
                        return this.tokenServer.questionnaireRegister(email, token, "x1", hash);
                    })
                    .then(response => {
                        this.authService.setResponseObject(response);
                        return this.router.navigate(Routes.CARDS_VALUES.addFragment(token));
                    })
                    .catch(reason => 
                    {
                        this.isBusy = false;
                        if ((reason.status) && reason.status == "402")
                        {
                            // Payment due ...
                            return this.router.navigate(Routes.CLIENT_ASSESSMENT);//, { id: this.questionnaireToken }, { trigger: true, replace: true });        
                        }
                        else 
                        {
                            this.errorObject = new ErrorObject(
                                reason,
                                this,
                                'Load current questionnaire token for user failed', 
                                'Try again?');       
                        }
                    });
            }
       }
    }

    public attached(): any
    {
        this.isLoading = false;
        this.isBusy = false;
    }

    public logout() {
        this.isBusy = false;
        this.authService.logout();
    }

    public authenticate(provider: string): any {
        if (!this.isBusy) {
            this.isBusy = true;
            this.authService.setResponseObject(null);

            // Note this will bring up a dialog window if the user has not previously registered ...
            this.authService.authenticate(provider, null, { registrationToken: "registered" })
                .then(response => {
                    this.router.navigate(Routes.CLIENT_LIST);
                })
                .catch(reason => 
                {
                    this.errorObject = new ErrorObject(
                        reason,
                        this,
                        'Google login failed - most likely an issue with the popup window', 
                        reason.data);       
                    this.isBusy = false;
                });
        }
    }
}
