import {autoinject, computedFrom} from 'aurelia-framework';
import {Router, RouteConfig} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {GlobalState, IGlobalState} from '../models/GlobalState';
import {ErrorObject} from '../models/ErrorObject';
import {QuestionServer} from '../server/QuestionServer';
import {CardsScaleResult} from '../models/ScaleResult';
import {clearFullDisplay} from '../resources/navigation/NextPage'

@autoinject
@clearFullDisplay()
export class CardSortAnalysisGroupedViewModel implements IGlobalState {

    public results: CardsScaleResult = new CardsScaleResult();
    public errorObject: ErrorObject = null;
    public isBusy: boolean;
    protected setId: string = '1';
    private roles: string[] = [];

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, protected authService: AuthService) {
        let payload = <any>this.authService.getTokenPayload();
        if (payload && payload.role)   
        {
            this.roles = payload.role;  
        }
    }

    public activate(params: any, route: RouteConfig): any {
        if (params.id && params.id.length >= 36)
        {
            if (!this.globalState.questionnaire.id || !this.globalState.questionnaire.id != params.id)
            {
                this.globalState.questionnaire.id = params.id;
                this.globalState.questionnaire.loaded = false;
            }
        } 
        else if (this.globalState.Id)  
        {
            // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
            this.router.navigateToRoute( route.name, { 'id': this.globalState.Id }, { trigger: false, replace: true });
        }        

        if (this.globalState.questionnaire.id) {
            return this.server.loadCardsGroupedAnalysis(this.globalState.questionnaire.id, this.setId, this.globalState, this.results)
                .catch(reason => 
                {
                    this.errorObject = new ErrorObject(
                        reason,
                        this.globalState.questionnaire,
                        'Load Card Analysis Results', 
                        'There has been an error reported attempting to load the analysis of the card data. Try again?');       
                });
        }
    }

    @computedFrom("roles")
    public get isReportAccess(): boolean
    {
        return this.roles.indexOf("User") >= 0 || this.roles.indexOf("QuestionnaireAnalysis") >= 0;
    }
    
    @computedFrom("roles")
    public get isFullAccess(): boolean
    {
        return this.roles.indexOf("User") >= 0;
    }
}    

