import {autoinject, computedFrom} from 'aurelia-framework';
import {RouteConfig} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';

const authenticatedDependency: string = 'authService.authenticated';
const standardUserRole: string = 'User';
const questionnaireUserRole: string = 'QuestionnaireUser';
const administratorUserRole: string = 'Administrator';

@autoinject
export class SecurityRoles {

    constructor(private authService: AuthService) {
    }

  /**
     * Determine if the user is authenticated and their associated roles
     *
     * @return {string[]} An array of Roles that are associated with the user
     */
    public get Roles(): string[] {
        let authenticated = this.authService.authenticated;
        if (authenticated)
        {
            // return an array of roles. will Check to see if the user is actually a normal user ...
            let payload = <any>this.authService.getTokenPayload();
            if (payload)
            {
                return SecurityRoles.GetRoles(payload.role);
            }

            return ['N/A'];
        }
        
        return [];
    }

  /**
     * Return an array of Roles from either an input string separated by "," or an array object
     *
     * @return {string[]} An array of Roles that are associated with the user
     */
    public static GetRoles(rolesPayload: string | string[]): string[] {
        if (rolesPayload)
        {
            if (typeof(rolesPayload) === 'string')
            {
                return rolesPayload.split(",");
            }
            else if (Array.isArray(rolesPayload))
            {
                return rolesPayload;
            }
        }
        
        return [];
    }
    

  /**
     * This checks if the routeConfig has any authentication requirements.
     * If it does then the user must be authenticated ...
     * If it has role requirements, then the user must posses one of the specified roles.
     *
     * @return {boolean} True if the route passes the authorisation and authentication requirements.
     */
    public static RouteAuthorisationFilter(routeConfig: RouteConfig, roles: string[], isAuthenticated: boolean): boolean
    {
        return (routeConfig 
                && !routeConfig.hasOwnProperty('auth') || routeConfig['auth'] === isAuthenticated && (!routeConfig.hasOwnProperty('roles') || SecurityRoles.GetRoles(routeConfig['roles']).some(requiredRole => roles.indexOf(requiredRole) >= 0)));
    //return (routeConfig && typeof (<any>routeConfig).auth !== 'boolean' || (<any>routeConfig).auth == isAuthenticated && typeof (<any>routeConfig).roles !== 'string'|| (<string>(<any>routeConfig).roles).split(",").some(requiredRole => roles.indexOf(requiredRole) >= 0));
    }


  /**
     * Determine if the user is authenticated as a plain "User"
     *
     * @return {boolean} Returns true if the user is authenticated and has the role "User"
     */
    @computedFrom(authenticatedDependency)
    public get isAuthenticated(): boolean {
        return this.Roles.indexOf(standardUserRole) >= 0;
    }

  /**
     * Determine if the user is authenticated as only a "QuestionnaireUser"" and not a plain "User"
     *
     * @return {boolean} Returns true if the user is authenticated and has the role QuestionnaireUser" and does not have the role "User".
     */
    @computedFrom(authenticatedDependency)
    public get isQuestionnaireUser(): boolean {
        let roles = this.Roles;
        return roles.indexOf(standardUserRole) == -1 && roles.indexOf(questionnaireUserRole) >= 0;
    }

    @computedFrom(authenticatedDependency)
    public get isAdministrator(): boolean {
        let roles = this.Roles;
        return roles.indexOf(standardUserRole) >= 0 && roles.indexOf(administratorUserRole) >= 0 && roles.indexOf(questionnaireUserRole) == -1;
    }
}     

