import {autoinject} from 'aurelia-framework';
import {Router, RouteConfig} from 'aurelia-router';
import {RiasecResult, IRiasecResults} from '../models/RiasecResult';
import {RiasecQuestionnaireInfo, IRiasecQuestionnaireInfo} from '../models/RiasecQuestionnaireInfo';
import {GlobalState} from '../models/GlobalState';
import {ScaleResult} from '../models/ScaleResult';
import {QuestionServer} from '../server/QuestionServer';
import {nextPage, clearFullDisplay} from '../resources/navigation/NextPage'
import {ErrorObject} from '../models/ErrorObject';
import {Routes} from '../routes';

@autoinject
@nextPage(Routes.ANALYSIS_CARDS_COMBINED)
@clearFullDisplay()
export class RiasecResults implements IRiasecQuestionnaireInfo, IRiasecResults {

    public careerA: string;
    public careerB: string;
    public careerRiasecA: string;
    public careerRiasecB: string;
    public notes: string;
    public riasecResults: RiasecResult[];
    public riasecPercent: RiasecResult[];
    public scaleResults: ScaleResult[];
    public rResults: ScaleResult[];
    public iResults: ScaleResult[];
    public aResults: ScaleResult[];
    public sResults: ScaleResult[];
    public eResults: ScaleResult[];
    public cResults: ScaleResult[];
    public careers: any[];
    public careerLevels: any[];
    public cardSorts: any[];
    public info: RiasecQuestionnaireInfo;
    public isBusy: boolean = false;
    public errorObject: ErrorObject = null;

    constructor(protected router: Router, protected globalState: GlobalState, private server: QuestionServer) {
        this.info = this.loadupInfo();
    }

    public activate(params: any, route: RouteConfig): any {
        if (params.id && params.id.length >= 36)
        {
            if (!this.globalState.questionnaire.id || !this.globalState.questionnaire.id != params.id)
            {
                this.globalState.questionnaire.id = params.id;
                this.globalState.questionnaire.loaded = false;
            }
        } else if (this.globalState.Id)  
        {
            // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
            this.router.navigateToRoute( route.name, { 'id': this.globalState.Id }, { trigger: false, replace: true });
        }

        if (this.globalState.questionnaire.id) {
            return this.server.loadRiasecAnalysis(this.globalState.questionnaire.id, this.globalState, this, this)
                .then(response => {
                    this.info = this.loadupInfo();
                })
                .catch(response => {
                    this.errorObject = new ErrorObject(
                        response,
                        null,
                        "Load Questionnaire RIASEC analysis", 
                        "The RIASEC questionnaire analysis data load has failed.");       
                });
        }
    }

    private loadupInfo(): RiasecQuestionnaireInfo {
        let info = new RiasecQuestionnaireInfo();
        info.careerA = this.careerA;
        info.careerB = this.careerB;
        info.careerRiasecA = this.careerRiasecA;
        info.careerRiasecB = this.careerRiasecB;
        info.notes = this.notes;
        return info;
    }
}   