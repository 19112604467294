import {autoinject, computedFrom} from 'aurelia-framework';
import {Router,RouteConfig} from 'aurelia-router';
import {RiasecResult, IRiasecResults} from '../models/RiasecResult';
import {IRiasecQuestionnaireInfo} from '../models/RiasecQuestionnaireInfo';
import {GlobalState} from '../models/GlobalState';
import {ScaleResult} from '../models/ScaleResult';
import {AuthService} from 'aurelia-authentication';
import {QuestionServer} from '../server/QuestionServer';
import {backPage, clearFullDisplay} from '../resources/navigation/NextPage'
import {ErrorObject} from '../models/ErrorObject';

@autoinject
@backPage()
@clearFullDisplay()
export class SpecialisedCardSortAnalysis implements IRiasecQuestionnaireInfo, IRiasecResults {

    public careerA: string;
    public careerB: string;
    public careerRiasecA: string;
    public careerRiasecB: string;
    public notes: string;
    public riasecResults: RiasecResult[];
    public riasecPercent: RiasecResult[];
    public scaleResults: ScaleResult[];
    public rResults: ScaleResult[];
    public iResults: ScaleResult[];
    public aResults: ScaleResult[];
    public sResults: ScaleResult[];
    public eResults: ScaleResult[];
    public cResults: ScaleResult[];
    public careers: any[];
    public careerLevels: any[];
    public cardSorts: any[];
    public lvl: number = 0;
    public isBusy: boolean = false;
    public errorObject: ErrorObject = null;
    public fullPrintOption: boolean = true;
    protected roles: string[] = [];

    constructor(protected router: Router, protected globalState: GlobalState, private server: QuestionServer, protected authService: AuthService) {
        this.fullPrintOption = this.authService.authenticated;

        let payload = <any>this.authService.getTokenPayload();
        if (payload && payload.role)   
        {
            this.roles = payload.role;  
        }
    }

    public activate(params: any, route: RouteConfig): any {
        if (params.id && params.id.length >= 36)
        {
            if (!this.globalState.questionnaire.id || !this.globalState.questionnaire.id != params.id)
            {
                this.globalState.questionnaire.id = params.id;
                this.globalState.questionnaire.loaded = false;
            }
        } else if (this.globalState.Id)  
        {
            // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
            this.router.navigateToRoute( route.name, { 'id': this.globalState.Id }, { trigger: false, replace: true });
        }

        if (this.globalState.questionnaire.id) {
            return this.server.loadSpecialisedAnalysis(this.globalState.questionnaire.id, this.globalState, this, this)
                .then(response => {
                })
                .catch(response => {
                    this.errorObject = new ErrorObject(
                        response,
                        null,
                        "Load Questionnaire specialised analysis", 
                        "The questionnaire specialised analysis data load has failed.");       
                });
        }
    }

    @computedFrom("roles")
    public get isFullAccess(): boolean
    {
        return this.roles.indexOf("User") >= 0;
    }

    @computedFrom("fullPrintOption")
    public get isFullPrint(): boolean
    {
        return this.fullPrintOption;
    }
}   