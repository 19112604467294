import {autoinject, computedFrom} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {GlobalState, IGlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer';
import {ErrorObject} from '../models/ErrorObject';
import {backPage} from '../resources/navigation/NextPage'
import {CareerTheme, ICareerThemes} from '../models/CareerTheme';

@autoinject
@backPage()
export class CreativityCardSortCareers implements ICareerThemes, IGlobalState  {

    public themedCareers: CareerTheme[] = [];
    private roles: string[] = [];
    protected setId: string = '12';
    public errorObject: ErrorObject = null;
    public isBusy: boolean;

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, protected authService: AuthService) {
        let payload = <any>this.authService.getTokenPayload();
        if (payload && payload.role)   
        {
            this.roles = payload.role;  
        }
    }

    public activate(): any {
        if (this.globalState.questionnaire.id) 
        {
            return this.server.loadCareerThemeReport(this.globalState.questionnaire.id , this.setId, this.globalState, this)
                .catch(reason => 
                {
                    this.errorObject = new ErrorObject(
                        reason,
                        this.globalState.questionnaire,
                        'Load Themed Career Report', 
                        'There has been an error reported attempting to load the themed career report analysis of the card data. Try again?');       
                    this.isBusy = false;
                });
        } 
    }

    @computedFrom("roles")
    public get isFullAccess(): boolean
    {
        return this.roles.indexOf("User") >= 0;
    }
}   
