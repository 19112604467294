import {autoinject} from 'aurelia-framework';
import {QuestionServer} from '../server/QuestionServer';
import {AuthService} from 'aurelia-authentication';
import {SecurityRoles} from '../models/SecurityRoles';
import {GlobalState} from '../models/GlobalState';
import {backPage, clearFullDisplay} from '../resources/navigation/NextPage'

@autoinject
@backPage()
@clearFullDisplay()
export class QuestionsEnd {

    constructor(protected globalState: GlobalState, private server: QuestionServer, private authService: AuthService, private securityRoles: SecurityRoles) {
    }

    public activate(params) {
        // This is simlar to logout, but we don't want to change the page displayed which is what happens if logout is used.
        // We need to do this so that an admin user can login again ... but we need to do it after the end call has been done, else the end call fails due to no access token.
        this.server.endOfQuestionniareReached(this.globalState.questionnaire.id)
            .then(() => {
                if (this.securityRoles.isQuestionnaireUser)
                {
                    this.globalState.questionnaire.id = null;
                    this.globalState.questionnaire.loaded = false;
                    this.globalState.clearFooterDetails();
                    this.authService.setResponseObject(null);
                }
        
            })
            .catch(() =>
            {
                if (this.securityRoles.isQuestionnaireUser)
                {
                    this.globalState.questionnaire.id = null;
                    this.globalState.questionnaire.loaded = false;
                    this.globalState.clearFooterDetails();
                    this.authService.setResponseObject(null);
                }
            });
    }
}
