import {autoinject} from 'aurelia-framework';
import {AuthService} from 'aurelia-authentication';
import {Router} from 'aurelia-router';
import {SecurityRoles} from '../models/SecurityRoles';
import {GlobalState} from '../models/GlobalState';
import {CardSortCore} from '../models/CardSortCore';
import {QuestionServer} from '../server/QuestionServer'
import {backPage, clearFullDisplay} from '../resources/navigation/NextPage'

@autoinject
@backPage()
@clearFullDisplay(true)
export class BusinessCardSort extends CardSortCore {
    
    constructor(router: Router, public globalState: GlobalState, server: QuestionServer, authService: AuthService, public securityRoles: SecurityRoles) {
        super(router, globalState, server, authService, securityRoles);
        this.setIdFragment = "5";
    }
}

