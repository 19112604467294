import {computedFrom} from 'aurelia-framework';
export enum selectType { isNone = 0, isNot, isSlight, isMaybe, isVery, isDefinite };

export class CardSortItem {
    private static readonly PURPOSE: string = "#Purpose#";
    private static readonly PREFERENCE: string = "#Preference#";

    public id: number;
    public leadin: string;
    public description: string;
    public explanation: string;
    public answer: number;
    public answerCode: string;
    private answerCodeInitial: string;
    public rank: number;
    private selectedType: selectType;
    
    /**
        * @return {string} The CSS class name to be uised to render this particular card in a card stack. This determines its position in the display stack.
        */
     @computedFrom("rank")
    public get cardClass(): string {
        if (this.rank >= 6)
        {
            return 'btn card r6' + this.cardBackground;
        }

        return 'btn card r' + this.rank + this.cardBackground;
    }

    /**
        * This is used to determine if this answer needs to be written out back to the server.
        *
        *
        * @return {boolean} Returns true if the answerCode has been modified
        */
    public get isAnswerModified(): boolean
    {
        if ((this.answerCode === "" && this.answerCodeInitial === null) || (this.answerCode === this.answerCodeInitial))
        {
            return false;
        }

        return true;
    }

    /**
        * The purpose override is only used on the values meanings card sort. It allows a given meaning to be moved between the purpose or preferences sections of the 
        * career values meanings page.
        * @return {boolean} Has this meaning been specifically (via the overrride) assigned to the purposes section
        */
    public get isPurposeOverride(): boolean
    {
        return (this.answerCode) && this.answerCode.includes(CardSortItem.PURPOSE);
    }

    public set isPurposeOverride(value: boolean) {
        this.answerCode = this.answerCode.replace(CardSortItem.PURPOSE,"");
        if ((value) && (value == true)){
            this.answerCode = this.answerCode + CardSortItem.PURPOSE;
        }
    }

    /**
        * The preference override is only used on the values meanings card sort. It allows a given meaning to be moved between the purpose or preferences sections of the 
        * career values meanings page.
        * @return {boolean} Has this meaning been specifically (via the overrride) assigned to the preferences section
        */
    public get isPreferenceOverride(): boolean
    {
        return (this.answerCode) && this.answerCode.includes(CardSortItem.PREFERENCE);
    }

    public set isPreferenceOverride(value: boolean) {
        this.answerCode = this.answerCode.replace(CardSortItem.PREFERENCE,"");
        if ((value) && (value == true)){
            this.answerCode = this.answerCode + CardSortItem.PREFERENCE;
        }
    }

    /**
        * This is used to sort given cards into a particular "pile" ranked from most ... down to not.
        * @return {selectType} The card pile in which the card has been assigned/sorted into by the user.
        */
    public get cardPile(): selectType {
        return this.selectedType;
    }

    public set cardPile(value: selectType)  {
        this.selectedType = value;
        switch (value) {
            case selectType.isNot:
                this.answer = 0;
                break;
            case selectType.isSlight:
                this.answer = 1;
                break;
            case selectType.isMaybe:
                this.answer = 2;
                break;
            case selectType.isVery:
                this.answer = 3;
                break;
            case selectType.isDefinite:
                this.answer = 4;
                break;
            default:
                this.answer = -1;
                break;
        }
    }

    public static toCardSortItem(obj: any): CardSortItem {
        let item = new CardSortItem();
        item.doObjToCardSortItem(obj);
        return item;
    }

    protected doObjToCardSortItem(obj: any): void  {
        this.id = obj.id;
        this.leadin = obj.leadinText;
        this.description = obj.cardText;
        this.explanation = obj.explanation;
        this.answerCodeInitial = (obj.answerCodeInitial) ? obj.answerCodeInitial : obj.answerCode;
        this.answerCode = (this.answerCodeInitial) ? this.answerCodeInitial : '';
        this.answer = obj.answer;
    
        switch (this.answer) {
            case 0:
                this.selectedType = selectType.isNot;
                break;
            case 1:
                this.selectedType = selectType.isSlight;
                break;
            case 2:
                this.selectedType = selectType.isMaybe;
                break;
            case 3:
                this.selectedType = selectType.isVery;
                break;
            case 4:
                this.selectedType = selectType.isDefinite;
                break;
            default:
                this.selectedType = selectType.isNone;
                break;
        }
    }

    private get cardBackground(): string {
        switch (this.cardPile) {
            case selectType.isDefinite:
                return ' definite';
            case selectType.isVery:
                return ' very';
            case selectType.isMaybe:
                return ' maybe';
            case selectType.isSlight:
                return ' slight';
            case selectType.isNot:
                return ' not';
            default:
                return ' maybe';

        }
    }
}    

export interface ICardList {
    cardList: CardSortItem[];
}

export interface ICardSortItems extends ICardList {
    notList: CardSortItem[];
    slightList: CardSortItem[];
    maybeList: CardSortItem[];
    veryList: CardSortItem[];
    definiteList: CardSortItem[];
}
