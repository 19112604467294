import {autoinject} from 'aurelia-framework';
import {AccountsServer} from '../server/AccountsServer';
import {Router} from 'aurelia-router';
import {GlobalState} from '../models/GlobalState';
import {ErrorObject} from '../models/ErrorObject';
import {backPage,clearFullDisplay} from '../resources/navigation/NextPage'

@autoinject
@backPage()
@clearFullDisplay(true)
export class AccountsRegister {

    public errorObject: ErrorObject = null;
    public isBusy: boolean = false;
    public userEmail: string = '';
    public name: string = '';
    public surname: string = '';
    public knownAsName: string = '';
    public reportKnownAsName: string = '';
    public company: string = '';
    public website: string = '';
    public phoneNumber: string = '';
    public linkSubject: string = '';
    public linkBody: string = '';
    public linkBodyToolTip: string = 'Hi ${Firstname} ${Surname},\r\n\
Here is the link to the questionnaire that you need to complete for your appointment.\r\n\
\r\n\
${QuestionnaireLink}\r\n\r\n\
If you have any questions please contact your career advisor.\r\n\
If you have an appointment booked then you need to answer this promptly. \r\n\
You need to use the latest versions of Chrome, Mozilla, Internet Explorer 11 or Safari (yes it does work on iPads - smart phones are workable but are not recommended)\r\n\
Regards\r\n';

    constructor(protected router: Router, protected globalState: GlobalState, private server: AccountsServer) {
    }

    public activate(): any {
        this.errorObject = ErrorObject.EmptyError();
        return this.server.getAccountProfile()
            .then(profile => 
            {
                this.userEmail = profile.userEmail;
                this.name = profile.knownAsName;
                this.reportKnownAsName = profile.reportKnownAsName;
                this.surname = profile.surname;
                this.company= profile.company;
                this.website = profile.website;
                this.phoneNumber = profile.phoneNumber;
                this.linkBody = profile.linkBody ? profile.linkBody : this.linkBodyToolTip;
                this.linkSubject = profile.linkSubject;
                return profile;
            });
    }

    public saveChanges(): any {
        if (!this.isBusy) {
            this.errorObject = ErrorObject.EmptyError();
            this.isBusy = true;
            return this.server.saveAccountProfile(
                    this.userEmail,
                    this.name,
                    this.phoneNumber,
                    this.surname,
                    this.website,
                    this.company,
                    this.reportKnownAsName,
                    this.linkBody,
                    this.linkSubject).then(response => {
                        this.isBusy = false;
                    })
                    .catch(reason => 
                    {
                        this.errorObject = new ErrorObject(
                            reason,
                            this,
                            'Save Account Profile Changes', 
                            'There has been an error reported attempting to saved the changed account profile data. Try again?');       
                        this.isBusy = false;
                    });
        }
    }
}
