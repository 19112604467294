import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {GlobalState} from '../models/GlobalState';
import {ErrorObject} from '../models/ErrorObject';
import {PaymentsServer} from '../server/PaymentsServer';
import {clearFullDisplay} from '../resources/navigation/NextPage';

// This is the landing page on return from the stripe payment portal. It just forwards the received parameters through to the server to deal with.

@autoinject
@clearFullDisplay(true)
export class AccountPayment {

    public errorObject: ErrorObject = null;
    private paymentReferenceId: string = '';
    private resultToken: string;
    private sessionToken: string;

    constructor(protected router: Router, protected globalState: GlobalState, private server: PaymentsServer) 
    {
    }

    public activate(params: any): Promise<any | boolean>
    {
        if (params.id && params.id.length === 36 && (params.resultToken) && (params.sessionToken))
        {
            this.paymentReferenceId = params.id;
            this.resultToken = params.resultToken;
            this.sessionToken = params.sessionToken;

            return this.server.paymentConfirmation(this.paymentReferenceId, this.resultToken, this.sessionToken)
            .then(payObj =>
                {
                    if (payObj.isPaid)
                    {
                        // Payment went through ... send them off to the first questionnaire page.
                        this.router.navigateToRoute("CARDS_VALUES", { id: payObj.questionnaireId }, { trigger: true, replace: false });                 
                    }
                    else
                    {   
                        // Failure ... we don't know why ... go back to the start page again ...
                        this.router.navigateToRoute("ACCOUNTS_ASSESSMENT", { token: payObj.questionnaireId, email: payObj.emailAddress, v: payObj.version, hash: payObj.hash }, { trigger: true, replace: false });                 
                    }
                }
            )
            .catch(response => 
                {
                    this.errorObject = new ErrorObject(
                        response,
                        null,
                        'Confirm Card Payment', 
                        response + ' There has been an error reported (which is outside our control). If this continues, please contact Career Sense. Try again?');       
                });
}
        else 
        {
            this.errorObject = new ErrorObject(
                'The value does not represent the expected payment identifier.',
                this.globalState.questionnaire,
                'Assessment Payments', 
                'Please contact Career Sense. This is not an expected payment scenario. Return to the start page and try again.');       
        }
    }

    public attached()
    {
    }
}
