import {RiasecQuestionnaire} from '../models/RiasecQuestionnaire';

export class GlobalState {
    public userId: string;
    public email: string = '';
    public phone: string;
    public sessionId: string;
    public questionnaire: RiasecQuestionnaire;
    public version: string;
    public firstname: string;
    public surname: string;
    public now: string;
    public users: any[] = [];

    constructor()
    {
        this.questionnaire = new RiasecQuestionnaire();
    }

    public clearFooterDetails()
    {
        this.firstname = '';
        this.surname = '';
        this.now = '';
        this.version = '';
    }
    
    public clearNowVersion()
    {
        this.now = '';
        this.version = '';
    }

    //
    //  Assign the common fields sent form the MutationObserver.
    //
    public assignCommonData(obj: any): void {
        this.firstname = obj.firstname;
        this.surname = obj.surname;
        this.version = obj.version;
        this.now = obj.completedAt;
    }

    public assignNames(obj: any): void {
        this.firstname = obj.firstname;
        this.surname = obj.surname;
        this.version = '';
        this.now = '';
    }

    public get Id(): string
    {
        return ((this.questionnaire) && (this.questionnaire.id)) ? this.questionnaire.id : "";
    }
}    

export interface IGlobalState {
    globalState: GlobalState;
}
