

export function nextPage(pageToNavigateTo:string)
{
    return function(target)
    {
        target.prototype.nextPage = function () {
            this.isNextPageDisabled = true;
            this.router.navigate(pageToNavigateTo, {});
            return false;
        };

        target.prototype.backPage = function () {
            this.router.navigateBack();
            return false;
        };

        target.prototype.allColumns = function () {
            this.isFullPrint = true;
            window.removeEventListener("afterprint", this.allColumns );
        }
        
        target.prototype.doPrintPage = function () {
            window.addEventListener("afterprint", this.allColumns );
            window.setTimeout(() => window.print(), 200);
        };

        target.prototype.printPage = function () {
            this.isFullPrint = false;
            this.doPrintPage();
        };

    }
}

export function backPage()
{
    return function(target)
    {
        target.prototype.backPage = function () {
            this.isNextPageDisabled = true;
            this.router.navigateBack();
            return false;
        };

        target.prototype.doPrintPage = function () {
            window.addEventListener("afterprint", this.allColumns );
            window.setTimeout(() => window.print(), 200);
        };

        target.prototype.printPage = function () {
            this.isFullPrint = false;
            this.doPrintPage();
        };

        target.prototype.allColumns = function () {
            this.isFullPrint = true;
            window.removeEventListener("afterprint", this.allColumns );
        }
    }
}

export function clearFullDisplay(clearNow: boolean = false)
{
    return function(target)
    {
        target.prototype.attached = function () {
            if (clearNow)
            {
                this.globalState.clearNowVersion();
            }
        };
    }

}
