import {ScaleResult} from '../models/ScaleResult';

export class RiasecResult {

    public riasecCode: string;
    public riasecType: number;
    public value: number;

    constructor() {
    }

    public static toRiasecResult(obj: any): RiasecResult {
        var itm = new RiasecResult();
        itm.riasecCode = obj.riasecCode;
        itm.riasecType = obj.riasectype;
        itm.value = obj.score;
        return itm;
    }
}     

export interface IRiasecResults {
    riasecResults: RiasecResult[];
    riasecPercent: RiasecResult[];
    scaleResults: ScaleResult[];
    rResults: ScaleResult[];
    iResults: ScaleResult[];
    aResults: ScaleResult[];
    sResults: ScaleResult[];
    eResults: ScaleResult[];
    cResults: ScaleResult[];
    careers: any[];
    careerLevels: any[];
    cardSorts: any[];
}

