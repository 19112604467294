import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {RiasecResult, IRiasecResults} from '../models/RiasecResult';
import {IRiasecQuestionnaireInfo} from '../models/RiasecQuestionnaireInfo';
import {IGlobalState, GlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer';
import {ScaleResult} from '../models/ScaleResult';
import {nextPage} from '../resources/navigation/NextPage'
import {Routes} from '../routes';

@autoinject
@nextPage(Routes.ANALYSIS_CARDS_GROUPED)
export class CombinedCardSortResults implements IGlobalState, IRiasecResults, IRiasecQuestionnaireInfo {

    public careerA: string;
    public careerB: string;
    public careerRiasecA: string;
    public careerRiasecB: string;
    public notes: string;
    public riasecResults: RiasecResult[];
    public riasecPercent: RiasecResult[];
    public scaleResults: ScaleResult[];
    public rResults: ScaleResult[];
    public iResults: ScaleResult[];
    public aResults: ScaleResult[];
    public sResults: ScaleResult[];
    public eResults: ScaleResult[];
    public cResults: ScaleResult[];
    public careers: any[];
    public careerLevels: any[];
    public cardSorts: any[];
    public isFullPrint: boolean = true;

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer) {
    }

    public activate(): any {
        if (this.globalState.questionnaire.id) {
            return this.server.loadCardsRiasecAnalysis(this.globalState.questionnaire.id, this.globalState, this, this);
        }
    }
}    