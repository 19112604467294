import {Routes} from './routes';

export default {
    endpoint: 'accounts',
    configureEndpoints: ['questions','accounts','payments','administration'],
    loginRoute: Routes.CLIENT_HOME.toHRef(),
    loginRedirect: Routes.CLIENT_LIST.toHRef(),
    logoutRedirect: '#/logout',
    loginUrl: Routes.CLIENT_HOME.toHRef(),
    signupUrl: '#/accounts/register',
    profileUrl: Routes.USER_PROFILE.toHRef(),
    unlinkUrl: '#/accounts/unlink',
    loginOnSignup: false,
    useRefreshToken: false,
    storageChangedReload: true,    // ensure secondary tab reloading after auth status changes
    expiredRedirect: 1,            // redirect to logoutRedirect after token expiration
    providers: {
        google: {
          url: 'register/google',
          clientId: '298927503135-i6nino6i4pn712jh5j1s5v2dsqse7au5.apps.googleusercontent.com',
          redirectUri: "",        // we set this later in code, depending on from where we have been loaded.
          platform: "browser",
          auth_uri:"https://accounts.google.com/o/oauth2/auth",
          token_uri:"https://oauth2.googleapis.com/token",
          auth_provider_x509_cert_url:"https://www.googleapis.com/oauth2/v1/certs"
        }
    }
};

