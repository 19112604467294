import {inject} from 'aurelia-framework';
import {Endpoint, Rest} from 'aurelia-api';
import {RiasecQuestionnaire} from '../models/RiasecQuestionnaire';


const registerUri: string = 'register';
const registerUserUri: string = 'userregistration';
const validateRegistrationTokenUri: string = 'validateRegistrationToken/';
const loginUri: string = 'register/google';
const sendQuestionnaireLinkUri: string = 'sendQuestionnaireLink';
const profileUri: string = 'profile';
const assessmentUri: string = 'assessment/'

@inject(Endpoint.of("accounts"))
export class AccountsServer {

    constructor(private endpoint: Rest) {
        (this.endpoint.defaults as any).retries = 3;
    }

    public validateRegistrationToken(token: string): Promise<any> {
        return this.endpoint.find(validateRegistrationTokenUri + token);
    }

    public register(registrationDetails: any): Promise<any> {
        return this.endpoint.post(registerUri, registrationDetails);
    }

    public login(registrationDetails: any): Promise<any> {
        return this.endpoint.post(loginUri, registrationDetails);
    }

    public loadAssessmentHeader(questionnaireId: string): Promise<RiasecQuestionnaire> {
        return this.endpoint.find(assessmentUri + questionnaireId)
            .then(data => {
                let questionnaire = new RiasecQuestionnaire();
                questionnaire.toQuestionnaire(data);
                return questionnaire;
            });
    }


    public sendQuestionnaireLink(email: string, thefirstName: string, thesurname: string, appointment: Date): Promise<any> {
        return this.endpoint.post(sendQuestionnaireLinkUri, 
            {
                emailAddress: email, 
                firstName: thefirstName, 
                surname: thesurname,
                appointment: appointment,
                isResend: false
            });
    }

    public resendQuestionnaireLink(id: string): Promise<any> {
        return this.endpoint.post(sendQuestionnaireLinkUri, 
            {
                emailAddress: "", 
                firstName: "", 
                surname: "",
                isDetailUpdate: false,
                isUpdateOnly: false,
                isResend: true,
                questionnaireId: id
            });
    }

    public updateQuestionnaireDetails(id: string, firstname: string, surname: string, emailAddr: string, appointment: Date): Promise<any> {
        return this.endpoint.post(sendQuestionnaireLinkUri, 
            {
                emailAddress: emailAddr, 
                firstName: firstname, 
                surname: surname,
                appointment: appointment,
                isResend: false,
                isDetailUpdate: true,
                isUpdateOnly: false,
                questionnaireId: id
            });
    }

    public updateQuestionnaireLink(id: string, isAnalysisViewable: boolean, isCardSortOccupationRequired: boolean, isCardSortHelpingRequired: boolean, isCardSortBusinessRequired: boolean, isCardSortEngineeringRequired: boolean, isCardSortScienceRequired: boolean, isCardSortArtsRequired: boolean, isCardSortTradesRequired: boolean, isCardSortCreativityRequired: boolean ): Promise<any> {
        return this.endpoint.post(sendQuestionnaireLinkUri, 
            {
                emailAddress: null, 
                firstName: null, 
                surname: null,
                isResend: false,
                isUpdateOnly: true,
                isDetailUpdate: false,
                questionnaireId: id,
                isAnalysisViewable: isAnalysisViewable,
                isCardSortOccupationRequired: isCardSortOccupationRequired,
                isCardSortHelpingRequired: isCardSortHelpingRequired,
                isCardSortBusinessRequired: isCardSortBusinessRequired,
                isCardSortEngineeringRequired: isCardSortEngineeringRequired,
                isCardSortScienceRequired: isCardSortScienceRequired,
                isCardSortArtsRequired: isCardSortArtsRequired,
                isCardSortTradesRequired: isCardSortTradesRequired,
                isCardSortCreativityRequired: isCardSortCreativityRequired
            });
    }

    public getAccountProfile(): Promise<any> {
        return this.endpoint.find(profileUri);
    }

    public saveAccountProfile(usrEmail: string, usrName: string, usrPhoneNumber: string, usrSurname: string, usrWebsite: string, usrCompany: string, usrReportKnownAs: string, updLinkBody: string, updLinkSubject: string): Promise<any> {
        return this.endpoint.update(profileUri, null,
            {
                userEmail: usrEmail, 
                knownAsName: usrName, 
                surname: usrSurname, 
                reportKnownAsName: usrReportKnownAs, 
                website: usrWebsite,
                company: usrCompany,
                phoneNumber: usrPhoneNumber,
                linkBody: updLinkBody,
                linkSubject: updLinkSubject
            });
    }

    public registerUser(usrEmail: string, usrName: string, usrPhoneNumber: string): Promise<any> {
        return this.endpoint.post(registerUserUri,
            {
                userEmail: usrEmail, 
                knownAsName: usrName, 
                phoneNumber: usrPhoneNumber
            });
    }

}

