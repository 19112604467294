import {autoinject, computedFrom} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {GlobalState} from '../models/GlobalState';
import {AdministrationServer} from '../server/AdministrationServer';
import {backPage} from '../resources/navigation/NextPage'
import {ErrorObject} from '../models/ErrorObject';
import {RoutePaths} from '../routes';

@autoinject
@backPage()
export class User extends RoutePaths {

    public users: any[] = [];
    public errorObject: ErrorObject = null;
    public isBusy: boolean = false;
    public isFileUploadBusy: boolean = false;
    public userId: string = "";
    public knownAsName: string = "";
    public company: string = "";
    public reportRate: number = 0;
    public assessmentRate: number = 0;
    public isAssessmentPaidViaPP: boolean = false;
    public billingGroupRuleId: string = "0";
    public reportReference: string = "";
    public reportTemplate: HTMLInputElement;
    public dropTargetElement: HTMLElement;
    private file: File;
    private static readonly docxFileType:string = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

    constructor(protected router: Router, protected globalState: GlobalState, private server: AdministrationServer) {
        super();
        this.globalState.clearFooterDetails();
    }

    public activate(params: any): any
    {
        if (params.id)
        {
            this.userId = params.id;
        }
    }

    public attached(): any 
    {
        //this.dropTargetElement.addEventListener("dragstart", this.dragNoAction, false);
        this.dropTargetElement.addEventListener("dragenter", (event) => this.dragCheckData(event), false);
        this.dropTargetElement.addEventListener("dragover", (event) => this.dragCheckData(event), false);
        this.dropTargetElement.addEventListener("drop", (event) => this.dragdrop(event), false);
        return this.server.loadUser(this.userId)
                .then(obj => {
                    this.userId = obj.userId;
                    this.knownAsName = obj.knownAsName;
                    this.company = obj.company;
                    this.reportRate = obj.reportRate;
                    this.assessmentRate = obj.assessmentRate;
                    this.isAssessmentPaidViaPP = obj.isAssessmentPaidViaPP;
                    this.billingGroupRuleId = obj.billingGroupRuleId.toString();
                    this.reportReference = obj.reportReference;
                })
                .catch(response => {
                    this.errorObject = new ErrorObject(
                        response,
                        null,
                        "Load Questionnaire List", 
                        "The data load of the questionnaire list has failed.");       
                });
    }

    public dettached(): any
    {
        this.dropTargetElement.removeEventListener("dragenter", (event) => this.dragCheckData(event), false);
        this.dropTargetElement.removeEventListener("dragover", (event) => this.dragCheckData(event), false);
        this.dropTargetElement.removeEventListener("drop", (event) => this.dragdrop(event), false);
    }

    @computedFrom("company","reportRate","assessmentRate","isAssessmentPaidViaPP")
    public get isUpdateNotValid(): boolean {
        return (this.company == null) || (this.reportRate == null)|| (this.assessmentRate == null) || (this.isAssessmentPaidViaPP == null);
    }

    public dragCheckData(event:DragEvent): void
    {
        event.stopPropagation();
        event.preventDefault();
        let data = event.dataTransfer;
        if (data.items.length == 1 && data.items[0].kind == "file" && data.items[0].type == User.docxFileType)
        {
            data.dropEffect = 'copy';
        }
        else
        {
            data.dropEffect = 'none';
        }
    }

    public dragdrop(event:DragEvent): void
    {
        event.stopPropagation();
        event.preventDefault();

        let data = event.dataTransfer;
        if (data.files.length == 1 && data.files[0].type == User.docxFileType)
        {
           data.dropEffect = 'copy';
           this.file = event.dataTransfer.files[0];
        }
        else
        {
            data.dropEffect = 'none';
            this.file = null;
        }
    }

    @computedFrom("file","reportTemplate.files")
    public get isFileNotPresent(): boolean
    {
        return !(this.file) && this.reportTemplate.files.length == 0;
    }

    public loadTemplate():any
    {
        if (!(this.file)) { 
            this.file = this.reportTemplate.files[0];
        }

        if (this.file)
        {
            let data = new FormData();
            data.append('file', this.file);
            this.errorObject = ErrorObject.EmptyError();
            this.isFileUploadBusy = true;
            return this.server.uploadReportTemplateFile(this.userId, data)
                .then(() => {
                    this.file = null;
                    this.isFileUploadBusy = false;
                })
                .catch(reason => 
                    {
                        this.errorObject = new ErrorObject(
                            reason,
                            this,
                            'Update Report Template', 
                            'There has been an error reported attempting to update the report template.');       
                        this.isFileUploadBusy = false;
                    });
        }
    }

    public updateDetails(): any
    {
        if (!this.isBusy) {
            this.errorObject = ErrorObject.EmptyError();
            this.isBusy = true;
            return this.server.saveUser(this.userId, 
                {
                    company: this.company,
                    reportRate: this.reportRate.valueOf(),
                    assessmentRate: this.assessmentRate.valueOf(),
                    isAssessmentPaidViaPP: this.isAssessmentPaidViaPP,
                    reportReference: this.reportReference,
                    billingGroupRuleId: Number.parseInt(this.billingGroupRuleId.toString())
                }).then(response => {
                        this.isBusy = false;
                        this.router.navigateBack();
                    })
                    .catch(reason => 
                    {
                        this.errorObject = new ErrorObject(
                            reason,
                            this,
                            'Update user', 
                            'There has been an error reported attempting to update user details on the system. Try again?');       
                        this.isBusy = false;
                    });
        }
        
    }
}   