import {autoinject,bindable, bindingMode} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {RiasecQuestionnaireInfo} from '../models/RiasecQuestionnaireInfo';
import {GlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer';
import {backPage} from '../resources/navigation/NextPage'
import {ErrorObject} from '../models/ErrorObject';
import {RoutePaths} from '../routes';

@autoinject
@backPage()
export class ClientResults extends RoutePaths {

    private fileURL: any;
    public questionnaires: RiasecQuestionnaireInfo[] = [];
    public errorObject: ErrorObject = null;

    @bindable({ defaultBindingMode: bindingMode.oneWay })
    public displayAll: boolean;

    constructor(protected router: Router, protected globalState: GlobalState, private server: QuestionServer) {
        super();
        this.globalState.clearFooterDetails();
        this.displayAll = false;
    }

    public activate(): any {
        return this.server.loadQuestionnaireList(this.displayAll)
                .then(response => {
                    this.questionnaires = response;
                })
                .catch(response => {
                    this.errorObject = new ErrorObject(
                        response,
                        null,
                        "Load Questionnaire List", 
                        "The data load of the questionnaire list has failed.");       
                });
    }

    public deactivate(): any 
    {
        if (this.fileURL)
        {
            window.URL.revokeObjectURL(this.fileURL);
        }
    }

    public toggleDisplayState(): any
    {
        this.displayAll = !this.displayAll;
        return this.server.loadQuestionnaireList(this.displayAll)
                .then(response => {
                    this.questionnaires = response;
                })
                .catch(response => {
                    this.errorObject = new ErrorObject(
                        response,
                        null,
                        "Load Client Questionnaire List", 
                        "The data load of the questionnaire list has failed.");       
                });
    }

    public downloadReport(event: Event):any
    {
        // Prevent default behavior when clicking a link
        event.preventDefault();

        if (this.fileURL)
        {
            window.URL.revokeObjectURL(this.fileURL);
        }

        // Get filename from href
        let careerElement = <Node>event.target;
        (<any>careerElement).Disabled = true;
        let busyIndicator = document.createElement('i');
        busyIndicator.className = 'fa fa-spinner fa-spin busy-indicator';
        careerElement.appendChild(busyIndicator);

        let item = <RiasecQuestionnaireInfo>(<any>careerElement).model;
        this.server.loadCareerReport(item.id)
                .then(blob => {

                    careerElement.removeChild(busyIndicator);

                    // IE doesn't allow using a blob object directly as link href
                    // instead it is necessary to use msSaveOrOpenBlob
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob);
                        return;
                    } 

                    this.fileURL = window.URL.createObjectURL(blob);

                    let element = document.createElement('a');
                    element.href = this.fileURL;
                    element.download = item.firstname + "." +  item.surname + ".CareerReport.docx";
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                 })
                .catch(response => {
                    careerElement.removeChild(busyIndicator);
                    this.errorObject = new ErrorObject(
                        response,
                        null,
                        "Generate Career Report", 
                        "Failed to download the generated report document.");       
                });
    }
}   