import {autoinject} from "aurelia-framework";
import {RoutePaths} from '../routes';

@autoinject
export class AdministrationMenu extends RoutePaths {

    constructor() {
        super();
    }

}
