
export class ScaleResult {

    public scaleCode: string = '';
    public description: string = '';
    public theme: string;
    public includeDescription: string;
    public ratio: number;
    public count: number;
    public value: number;
    public include: boolean = true;
    public cardTexts: string[];

    constructor() {
        this.cardTexts = [];
    }

    public static EmptyResult(): ScaleResult
    {
        let itm = new ScaleResult();
        itm.include = false;
        return itm;

    }

    public static toScaleResult(obj: any): ScaleResult {
        let itm = new ScaleResult();
        itm.scaleCode = obj.scaleCode;
        itm.ratio = obj.ratio;
        itm.count = obj.count;
        itm.value = obj.value;
        return itm;
    }

    public static toGroupedScaleResult(obj: any): ScaleResult {
        let itm = new ScaleResult();
        itm.scaleCode = obj.scaleCode;
        itm.description = (obj.description) ? obj.description : '';
        itm.includeDescription = itm.description;
        itm.theme = obj.theme;
        itm.ratio = obj.ratio;
        itm.count = obj.count;
        itm.value = obj.value;
        itm.include = obj.include == true;
        if (obj.cardTexts) {
            itm.cardTexts = obj.cardTexts;
        }

        return itm;
    }

    public get sortValue(): number
    {
        let d = this.description.trim();
        if (!(d) || d.length == 0) return 222;
        let result = 0;
        for (let index = 0; index < Math.min(d.length, 5); index++)
        {
            let cp = d.codePointAt(index);
            if (cp >= 48 && cp <= 57)
            {
                this.include = true;
                result = result * 10 + (cp - 48);
            }
            else if (result > 0)
            {
                return result;
            }
            else
            {
                return 222;
            }
        }

        return 222;
    }

    public get sortDescription(): string
    {
        for (let index = 0; index < Math.min(this.description.length, 10); index++)
        {
            let cp = this.description.codePointAt(index);
            if (cp == 32 || cp == 46 || (cp >= 48 && cp <= 57))
            {
                continue;
            }
            else
            {
                return this.description.substring(index);
            }
        }

        return '~zz' + this.description;
    }
}      

export class CardsScaleResult implements ICardsScaleResult {
    public scaleResults: ScaleResult[];
    public aResult: ScaleResult;
    public bResult: ScaleResult;
    public cResult: ScaleResult;
    public dResult: ScaleResult;
    public eResult: ScaleResult;
    public fResult: ScaleResult;
    public gResult: ScaleResult;
    public hResult: ScaleResult;
    public iResult: ScaleResult;
    public jResult: ScaleResult;
    public kResult: ScaleResult;
    public lResult: ScaleResult;
    public mResult: ScaleResult;
    public nResult: ScaleResult;
    public oResult: ScaleResult;
    public pResult: ScaleResult;
    public qResult: ScaleResult;
    public rResult: ScaleResult;
    public sResult: ScaleResult;
    public topValues: ScaleResult;
    public notes: string;

    public fixupResultsFromScaleResults(): void
    {
        this.aResult = this.scaleResults[0];
        this.bResult = this.scaleResults[1];
        this.cResult = this.scaleResults[2];
        this.dResult = this.scaleResults[3];
        this.eResult = this.scaleResults[4];
        this.fResult = this.scaleResults[5];
        this.gResult = this.scaleResults[6];
        this.hResult = this.scaleResults[7];
        this.iResult = this.scaleResults[8];
        this.jResult = (this.scaleResults[9])  ? this.scaleResults[9]  : ScaleResult.EmptyResult();
        this.kResult = (this.scaleResults[10]) ? this.scaleResults[10] : ScaleResult.EmptyResult();
        this.lResult = (this.scaleResults[11]) ? this.scaleResults[11] : ScaleResult.EmptyResult();
        this.mResult = (this.scaleResults[12]) ? this.scaleResults[12] : ScaleResult.EmptyResult();
        this.nResult = (this.scaleResults[13]) ? this.scaleResults[13] : ScaleResult.EmptyResult();
        this.oResult = (this.scaleResults[14]) ? this.scaleResults[14] : ScaleResult.EmptyResult();
        this.pResult = (this.scaleResults[15]) ? this.scaleResults[15] : ScaleResult.EmptyResult();
        this.qResult = (this.scaleResults[16]) ? this.scaleResults[16] : ScaleResult.EmptyResult();
        this.rResult = (this.scaleResults[17]) ? this.scaleResults[17] : ScaleResult.EmptyResult();
        this.sResult = (this.scaleResults[18]) ? this.scaleResults[18] : ScaleResult.EmptyResult();
    }
}

export interface ICardsScaleResult {
    scaleResults: ScaleResult[];
    aResult: ScaleResult;
    bResult: ScaleResult;
    cResult: ScaleResult;
    dResult: ScaleResult;
    eResult: ScaleResult;
    fResult: ScaleResult;
    gResult: ScaleResult;
    hResult: ScaleResult;
    iResult: ScaleResult;
    jResult: ScaleResult;
    kResult: ScaleResult;
    lResult: ScaleResult;
    mResult: ScaleResult;
    nResult: ScaleResult;
    oResult: ScaleResult;
    pResult: ScaleResult;
    qResult: ScaleResult;
    rResult: ScaleResult;
    sResult: ScaleResult;
    topValues: ScaleResult;
    notes: string;
    fixupResultsFromScaleResults(): void;
}